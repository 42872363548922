export const toFormattedPercentStripTrailingZerosAfterDecimalPoint = function (value: number): string {
    const stringNum = parseFloat(String(value * 100)).toFixed(2)
    if (stringNum.endsWith('.00')) {
        return stringNum.slice(0, stringNum.length - 3) + '%'
    }
    if (/\..0$/.test(stringNum)) {
        // match XX.X0 to drop the last 0
        return stringNum.slice(0, stringNum.length - 1) + '%'
    }
    return stringNum + '%'
}

export const toFormattedUSD = function (number: number): string {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(number)
}

export const toFormattedUSDStripTrailingZeroCents = (number: number): string => {
    let stringNum = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(number)

    stringNum = stringNum.replace(/\.00$/, '')

    return stringNum
}

const formatNumber = (number: number, fractionDigits?: number): string => {
    return new Intl.NumberFormat('en-US', {
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits,
    }).format(number)
}

export const toFormattedUSDKMOrB = (number: number, includePlusForPositive = false, includeDecimalPlaces: boolean = true): string => {
    const absNumber = Math.abs(number)
    let formattedNumber: string
    let suffix: string = ''

    if (absNumber >= 1_000_000_000) {
        formattedNumber = formatNumber(absNumber / 1_000_000_000, includeDecimalPlaces ? 2 : 0)
        suffix = 'B'
    } else if (absNumber >= 1_000_000) {
        formattedNumber = formatNumber(absNumber / 1_000_000, includeDecimalPlaces ? 2 : 0)
        suffix = 'M'
    } else if (absNumber >= 1000) {
        formattedNumber = formatNumber(absNumber / 1000, includeDecimalPlaces ? 1 : 0)
        suffix = 'K'
    } else {
        formattedNumber = formatNumber(absNumber, 0)
    }

    let sign = ''
    if (number >= 0) {
        sign = includePlusForPositive ? '+' : ''
    } else {
        sign = '-'
    }
    return `${sign}$${formattedNumber}${suffix}`
}
