import { defineStore } from 'pinia'
import { openReplay } from '@/utils/openReplay'
import { UpgradeData, UpgradeOffersState } from '@/store/upgradeOffersStore.types'
import { inspect, logger } from '@/utils/logger'
import { beginUccCardholderToHomeUpgradeDataFetchAndPreQualificationOffer, getIsAgentAvailable, getUccCardholderHomeUpgradePreQualOffer, sendCallbackRequest } from '@/services/avenAppApi'
import { AvenBoostRewardsName, HomeDataFetchAndPreQualificationOfferControllerResponse, LoanTerms, PreQualAction, UnderwritingMetadata } from 'aven_types'
import { DateTime } from 'luxon'
import { logEvent } from '@/services/http-client'
import { useIsUccProduct } from '@/utils/uccComposables'
import { useOverviewStore } from '@/store/overviewStore'
import { aprIsFootnote, autoPayDiscountFootnote, cashBackFootnote, contingentOfferFootnote, estimatedMonthlyPayments, fixedPlanFootnote } from 'aven_shared/src/localization/en'
import { AvenHomeCardUpgradeVariant } from '@/views/avenHomeCardUpgrade.types'

const PRE_QUAL_TIMEOUT_SECONDS = 70

// As of 2025-02-28, the default home upgrade policy is MOAMP (post2025FebHomeUpgradeMoap)
const NEW_UW_POLICY_NAME = 'defaultHomeUpgrade'

export const useUpgradeOffersStore = () => {
    const upgradeOffersStore = defineStore('upgradeOffers', {
        state: (): UpgradeOffersState => {
            return {
                [NEW_UW_POLICY_NAME]: {
                    loading: false,
                    underwritingMetadata: null,
                    // Todo This is an array of just one upgrade offer based on getUccCardholderHomeUpgradePreQualOffer()
                    //  on the backend. Should add another field that just returns the single offer, rather than array.
                    preQualOffers: [],
                    installmentLoanTerms: [],
                },
            }
        },
        getters: {
            upgradeData(state): UpgradeData | null {
                const upgradeData = state[NEW_UW_POLICY_NAME]
                if (!upgradeData) {
                    return null
                }
                return upgradeData
            },
            upgradeOffers(): LoanTerms[] {
                const upgradeData = this.upgradeData
                if (!upgradeData) {
                    return []
                }
                return upgradeData.preQualOffers
            },
            upgradeOfferUnderwritingMetadata(): UnderwritingMetadata | null {
                if (!this.upgradeData) {
                    return null
                }
                return this.upgradeData.underwritingMetadata ?? null
            },
            upgradeOfferToDisplay(): LoanTerms | null {
                const offers = this.upgradeOffers
                if (offers.length === 0) {
                    return null
                }
                return offers[0]
            },
            upgradeOfferToDisplayCashBackRatio(): number | null {
                const underwritingMetadata = this.upgradeOfferUnderwritingMetadata

                // This is weird ... but the below is how origination determines which cashback % to show
                // on both pre-qual and qual offer pages.
                // Todo Move this logic to the backend or python so that offers actually have the cashback ratio to display
                if (underwritingMetadata) {
                    // We shouldnt use boostCashbackRatio here because we have several boosts and boostCashbackRatio just contains the cashback percent of the first boost we find which is not guaranteed to be the allMerchant3pctAutoPayEnabledAnnualLimit that we want to show here.
                    const boostCashbackNames = underwritingMetadata.boostCashBackNames
                    for (const boostCashbackName of boostCashbackNames) {
                        if (boostCashbackName === AvenBoostRewardsName.allMerchant3pctAutoPayEnabledAnnualLimit) {
                            logger.info(`Found allMerchant3pctAutoPayEnabledAnnualLimit to returning 3%`)
                            return 0.03
                        }
                    }

                    logger.info(`Did not find allMerchant3pctAutoPayEnabledAnnualLimit to returning cashback ratio: ${underwritingMetadata.cashBackPointsToDollarRatio}`)
                    return underwritingMetadata.cashBackPointsToDollarRatio
                } else {
                    logger.info(`No underwriting metadata found, returning cashback ratio: ${this.upgradeOfferToDisplay.cashBack}`)
                    return this.upgradeOfferToDisplay.cashBack
                }
            },
            cashBackAnnualLimit(): number | null {
                if (this.upgradeOfferUnderwritingMetadata) {
                    // We shouldnt use boostCashBackDollarLimitInAccountYear here because we have several boosts and boostCashBackDollarLimitInAccountYear just contains the cashback percent of the first boost we find which is not guaranteed to be the allMerchant3pctAutoPayEnabledAnnualLimit that we want to show here.
                    const boostCashbackNames = this.upgradeOfferUnderwritingMetadata.boostCashBackNames
                    for (const boostCashbackName of boostCashbackNames) {
                        if (boostCashbackName === AvenBoostRewardsName.allMerchant3pctAutoPayEnabledAnnualLimit) {
                            return 10000
                        }
                    }
                }
                return null
            },
            upgradeLowestInstallmentMonthlyPayment(): number | null {
                const upgradeData = this.upgradeData
                if (!upgradeData) {
                    return null
                }
                const installmentLoanTerms = upgradeData.installmentLoanTerms
                if (!installmentLoanTerms || installmentLoanTerms.length === 0) {
                    return null
                }
                let lowestInstallmentMonthlyPayment = Number.MAX_VALUE
                for (const installment of installmentLoanTerms) {
                    if (installment.paymentStats.paymentSize && installment.paymentStats.paymentSize < lowestInstallmentMonthlyPayment) {
                        lowestInstallmentMonthlyPayment = installment.paymentStats.paymentSize
                    }
                }
                if (lowestInstallmentMonthlyPayment === Number.MAX_VALUE) {
                    return null
                }
                return lowestInstallmentMonthlyPayment
            },
        },
        actions: {
            getUpgradeDisclosures(variant: AvenHomeCardUpgradeVariant): string {
                if (!this.upgradeOfferUnderwritingMetadata) {
                    return ''
                }
                return `<p>${contingentOfferFootnote()}</p><p>${aprIsFootnote(
                    this.upgradeOfferUnderwritingMetadata,
                    'The Home annual percentage rate'
                )}</p><p>${autoPayDiscountFootnote()}</p><p>${cashBackFootnote(this.upgradeOfferUnderwritingMetadata)}</p><p>${fixedPlanFootnote()}</p>${
                    variant === AvenHomeCardUpgradeVariant.reduceMonthlyPayment ? `<p>${estimatedMonthlyPayments()}</p>` : ''
                }`
            },
            getUpgradeOfferReturnLink(originatingSourceName: string): string {
                const returnToken = useOverviewStore().returnToken
                return `${process.env.VUE_APP_AVEN_URL}/origination/asset_to_home/${returnToken}/${btoa(
                    NEW_UW_POLICY_NAME
                )}?utm_source=uccavenmy&utm_campaign=moampdryrunoffer&utm_content=${originatingSourceName}&cleanReload=true`
            },
            async pollForUccCardholderHomeUpgradePreQualOffer(startTime: DateTime, jobId: string): Promise<HomeDataFetchAndPreQualificationOfferControllerResponse> {
                if (!useIsUccProduct().isUccProduct.value) {
                    throw new Error(`Current cardholder is not UCC so cannot poll for home upgrade offer`)
                }
                let response
                let numAttempt: number = 0
                while (startTime > DateTime.now().plus({ seconds: -PRE_QUAL_TIMEOUT_SECONDS })) {
                    numAttempt += 1
                    response = await getUccCardholderHomeUpgradePreQualOffer(jobId)
                    const timeElapsedSeconds = DateTime.now().diff(startTime, ['seconds']).toObject().seconds
                    if (response.data.success && response.data.payload) {
                        logger.info(`UCC cardholder home upgrade preQual underwriting is complete. Response: ${inspect(response.data)}`)
                        logEvent('event_ucc_cardholder_home_upgrade_data_fetch_and_prequal_completed', {
                            payload: response.data.payload,
                            timeElapsedSeconds,
                        })
                        return response.data.payload
                    }
                    logger.info(`Still waiting for UCC cardholder home upgrade data fetch and preQual underwriting to complete. Attempt nr ${numAttempt + 1}`)
                    // Latency optimization for approved case. When we approve a case, 85% of latencies fall into
                    // the window of [15, 30] seconds. Within this time window, poll more aggressively.
                    const timeToWaitMs = timeElapsedSeconds && timeElapsedSeconds > 15 && timeElapsedSeconds < 30 ? 500 : 3_000 // milliseconds
                    await new Promise((r) => setTimeout(r, timeToWaitMs))
                }
                logger.info('UCC cardholder home upgrade data fetch and preQual failed, returning failed data fetch')
                return { preQualAction: PreQualAction.underwritingFail, underwritingResult: null }
            },
            async tryRunAndGetUccCardholderHomeUpgradeOffer(): Promise<void> {
                if (!useIsUccProduct().isUccProduct.value) {
                    logger.info(`Current cardholder is not UCC so not running/getting home upgrade offer`)
                    return
                }
                if (this.upgradeData.loading) {
                    logger.info(`UCC cardholder home upgrade data is loading, not loading again`)
                    return
                }
                if (this.upgradeOfferToDisplay) {
                    logger.info(`UCC cardholder home upgrade data already loaded, not loading again`)
                    return
                }
                try {
                    this.upgradeData.loading = true
                    const startTime: DateTime = DateTime.now()
                    logger.info(`Beginning to run & get UCC cardholder home upgrade offer`)
                    const beginResponse = await beginUccCardholderToHomeUpgradeDataFetchAndPreQualificationOffer(NEW_UW_POLICY_NAME)
                    if (!beginResponse.data.success) {
                        logger.info(`Unsuccessful beginning UCC cardholder home upgrade. Error: ${beginResponse.data.error}`)
                        return
                    }
                    const jobId = beginResponse.data.payload.jobId
                    logger.info(`Successfully began to run & get UCC cardholder home upgrade offer in job ${jobId}`)

                    const preQualOfferResult = await this.pollForUccCardholderHomeUpgradePreQualOffer(startTime, jobId)
                    logger.info(`Polling for UCC cardholder home upgrade offer in job ${jobId} is complete w/ result ${inspect(preQualOfferResult)}`)
                    if (preQualOfferResult.preQualAction === PreQualAction.underwritingFail) {
                        await logEvent('event_ucc_cardholder_to_home_upgrade_data_fetch_and_prequal_timed_out', { result: preQualOfferResult })
                        return
                    }
                    await logEvent('event_ucc_cardholder_to_home_upgrade_data_fetch_and_prequal_completed', {
                        result: preQualOfferResult,
                    })

                    if (preQualOfferResult.preQualAction !== PreQualAction.showPrequal) {
                        logger.info(`UCC cardholder home upgrade offer in job ${jobId} has result ${preQualOfferResult.preQualAction}, so not storing upgrades`)
                        return
                    }
                    if (!preQualOfferResult.underwritingResult?.preQualOffers || preQualOfferResult.underwritingResult.preQualOffers.length === 0) {
                        logger.fatal(`UCC cardholder home upgrade offer in job ${jobId} has result ${PreQualAction.showPrequal}, but response has no offers!`)
                        return
                    }

                    // If the cardholder switches between cards, we could have an upgrade offer polling
                    // for card A when card B comes into scope. This block helps control that and avoid
                    // storing upgrades for the wrong card.
                    if (!useIsUccProduct().isUccProduct.value) {
                        logger.info(`After polling, current cardholder is not UCC so not storing home upgrade offers`)
                        return
                    }

                    logger.info(`UCC cardholder home upgrade offer in job ${jobId} has result ${PreQualAction.showPrequal} w/ offers: ${inspect(preQualOfferResult.underwritingResult.preQualOffers)}`)
                    this.$patch({
                        [NEW_UW_POLICY_NAME]: {
                            underwritingMetadata: preQualOfferResult.underwritingMetadata,
                            preQualOffers: preQualOfferResult.underwritingResult.preQualOffers,
                            installmentLoanTerms: preQualOfferResult.installmentLoanTerms ?? [],
                        },
                    })
                } catch (e) {
                    logger.fatal(`Error trying to run & get UCC cardholder home upgrade offer`, e)
                } finally {
                    this.upgradeData.loading = false
                }
            },
            async getMloAvailability(): Promise<boolean> {
                const response = await getIsAgentAvailable()
                return response.data.success
            },
            async sendCallbackRequest(): Promise<void> {
                await sendCallbackRequest()
            },
        },
    })()
    openReplay.setUpPiniaStoreTracking(upgradeOffersStore)
    return upgradeOffersStore
}
