import { UpgradeCardType } from 'aven_types'

/**
 * Used for:
 * 1. Never displaying UpgradeCardType.none
 * 2. Controlled rollout of cards (i.e. add the enum before getting the card UI set up)
 */
export const shouldDisplayUpgradeCardType: Record<UpgradeCardType, boolean> = {
    [UpgradeCardType.none]: false,
    [UpgradeCardType.genericAvenCash]: true,
    [UpgradeCardType.personalLoanRefi]: true,
    [UpgradeCardType.creditCardBalanceTransfer]: true,
    [UpgradeCardType.genericMoap]: true,
    [UpgradeCardType.dryrunMoap]: true,
}
