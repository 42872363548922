import { useOverviewStore } from '@/store/overviewStore'
import { computed } from 'vue'

export const useIsUccProduct = () => {
    const isUccProduct = computed(() => {
        return useOverviewStore().isUccProduct
    })

    return { isUccProduct }
}
