<script setup lang="ts">
    import { onMounted, ref } from 'vue'
    import { HumanReadableLocationForPromotion, PromotionName } from 'aven_types'
    import { logEvent } from '@/services/http-client'
    import BaseButton from '@/components/base/BaseButton.vue'
    import CardBlock from '@/components/CardBlock.vue'
    import SectionHeader from '@/components/SectionHeader.vue'
    import { RoutePaths } from '@/routes/router.types'
    import { useQuickPromotionCommons, useQuickPromotionFeedUnitCommons, useRestaurants7PromotionCommons } from '@/composables/useQuickPromotionCommons'
    import { logger } from '@/utils/logger'

    const loading = ref(true)
    const promotionName = PromotionName.restaurants7Percent
    const promotionPagePath = RoutePaths.RESTAURANTS_7_PROMOTION

    const { shouldShowEnrolledFeedUnit, shouldShowClaimedFeedUnit, submitting, trySetShouldShowFeedUnitRefs, onClaimFeedUnit } = useQuickPromotionFeedUnitCommons(promotionName, promotionPagePath)
    const { copiedToClipboardRef, supportsNativeShare, humanReadableLocation, shareWithFriends, copyLink, trySetHumanReadableLocationForCustomer } = useQuickPromotionCommons(promotionName)
    const { getRestaurants7ShareMessageForLocation } = useRestaurants7PromotionCommons()

    // Set these up once we have location information
    const message = ref<string>('')
    const copyLinkWithMessageFn = () => {
        copyLink(message.value)().catch((error) => {
            logger.error('Error copying link', error)
        })
    }
    const shareWithFriendsWithMessageFn = () => {
        shareWithFriends(message.value)().catch((error) => {
            logger.error('Error shareWithFriendsWithMessageFn', error)
        })
    }

    onMounted(async () => {
        loading.value = true
        await trySetShouldShowFeedUnitRefs()
        await trySetHumanReadableLocationForCustomer()
        message.value = getRestaurants7ShareMessageForLocation(humanReadableLocation.value)

        // We temporarily want to shut off Ohio
        if (humanReadableLocation.value === HumanReadableLocationForPromotion.ohio) {
            shouldShowEnrolledFeedUnit.value = false
            shouldShowClaimedFeedUnit.value = false
        }

        loading.value = false
        if (shouldShowEnrolledFeedUnit.value === true) {
            logEvent('view_restaurants_7_promotion_feed_unit', { humanReadableLocation: humanReadableLocation.value })
        }
        if (shouldShowClaimedFeedUnit.value === true) {
            logEvent('view_restaurants_7_promotion_claimed_feed_unit', { humanReadableLocation: humanReadableLocation.value })
        }
    })
</script>

<template>
    <div>
        <div
            v-if="shouldShowEnrolledFeedUnit"
            class="mb-2"
        >
            <section-header override-class="container mt-3 mb-1 text-left">
                {{ $t('pages.RestaurantsPromotion.feedUnit.yourOffers') }}
            </section-header>
            <card-block
                class="card-block"
                :no-horizontal-padding="true"
                :no-vertical-padding="true"
            >
                <div class="tw-p-6">
                    <div
                        class="tw-font-bold tw-text-lg"
                        v-html="$t('pages.RestaurantsPromotion.feedUnit.title')"
                    />
                    <div class="d-flex flex-row tw-mb-[18px] align-items-center">
                        <div class="flex-column">
                            <div
                                class="tw-text-sm tw-mr-2"
                                v-html="$t('pages.RestaurantsPromotion.feedUnit.description')"
                            />
                        </div>
                        <img
                            v-if="!loading && humanReadableLocation === 'Ohio'"
                            alt="Restaurants"
                            class="tw-w-[96px]"
                            src="@/assets/images/quickPromotions/ohioRestaurantsEllipses.webp"
                        >
                        <!-- Fallback can just be phoenix: the ellipses are small enough to where it's not an issue. -->
                        <img
                            v-else-if="!loading"
                            alt="Restaurants"
                            class="tw-w-[96px]"
                            src="@/assets/images/quickPromotions/phoenixRestaurantsEllipses.webp"
                        >
                    </div>
                    <base-button
                        @click="onClaimFeedUnit"
                        :submitting="submitting"
                        data-testid="restaurants7-claim-button"
                    >
                        {{ $t('pages.RestaurantsPromotion.feedUnit.claimButton') }}
                    </base-button>
                </div>
            </card-block>
        </div>
        <!-- I know it seems ugly to completely duplicate the template here but this template will likely change as designs change -->
        <div
            v-else-if="shouldShowClaimedFeedUnit"
            class="mb-2"
        >
            <card-block
                class="card-block"
                :no-horizontal-padding="true"
                :no-vertical-padding="true"
            >
                <div class="tw-p-6">
                    <div
                        class="tw-font-bold tw-mb-2 tw-text-lg"
                        v-html="$t('pages.RestaurantsPromotion.feedUnit.claimedTitle')"
                    />
                    <div class="d-flex flex-row tw-mb-[18px] align-items-center">
                        <img
                            v-if="!loading && humanReadableLocation === 'Ohio'"
                            alt="Restaurants"
                            class="tw-w-[96px] tw-mr-4"
                            src="@/assets/images/quickPromotions/ohioRestaurantsEllipses.webp"
                        >
                        <img
                            v-else-if="!loading"
                            alt="Restaurants"
                            class="tw-w-[96px] tw-mr-4"
                            src="@/assets/images/quickPromotions/phoenixRestaurantsEllipses.webp"
                        >
                        <div class="flex-column">
                            <div
                                class="tw-text-sm"
                                v-html="$t('pages.RestaurantsPromotion.feedUnit.claimedDescription')"
                            />
                        </div>
                    </div>
                    <base-button
                        v-if="supportsNativeShare === true"
                        @click="shareWithFriendsWithMessageFn"
                        @keydown.enter="shareWithFriendsWithMessageFn"
                        button-classes="btn btn-secondary btn-block"
                        :submitting="submitting"
                        data-testid="restaurants7-share-button"
                    >
                        {{ $t('pages.RestaurantsPromotion.feedUnit.shareWithFriends') }}
                    </base-button>
                    <base-button
                        v-else
                        image-alt="Copy"
                        @click="copyLinkWithMessageFn"
                        image-classes="tw-inline-block tw-me-2"
                        button-classes="btn btn-secondary btn-block"
                        :image-src="copiedToClipboardRef ? '' : 'images/pages/card/Link.svg'"
                        data-testid="restaurants7-share-message"
                    >
                        {{ copiedToClipboardRef ? $t('shared.copied') : $t('pages.RestaurantsPromotion.feedUnit.shareWithFriends') }}
                    </base-button>
                </div>
            </card-block>
        </div>
    </div>
</template>
