<template>
    <div class="d-flex justify-content-around md:tw-px-4">
        <common-action-button
            v-for="button in buttons"
            :key="button.text + button.iconSrc"
            :icon-src="button.iconSrc"
            :text="button.text"
            :invert-icon-color="button.invertIconColor"
            @click="handleButtonClick(button)"
            :data-testid="button.dataTestId"
            v-show="showCondition(button.title)"
        />
    </div>
</template>
<script lang="ts">
    import CommonActionButton from '@/components/CommonActionButton.vue'
    import { useOverviewStore } from '@/store/overviewStore'
    import { RouteNames, RoutePaths } from '@/routes/router.types'
    import { useUpgradeOffersStore } from '@/store/upgradeOffersStore'
    import { AvenHomeCardUpgradeVariant } from '@/views/avenHomeCardUpgrade.types'

    enum ButtonTitle {
        MakePayment = 'MakePayment',
        BalanceTransfer = 'BalanceTransfer',
        CashOut = 'CashOut',
        /** Cash outs are disabled for UCC. If eligible, this button will appear and take you to a home upgrade offer on click. */
        ActivateBankTransfer = 'ActivateBankTransfer',
    }

    export default {
        name: 'CommonActions',
        components: {
            CommonActionButton,
        },
        data() {
            return {
                overviewStore: useOverviewStore(),
                upgradeOffersStore: useUpgradeOffersStore(),
                buttons: [
                    {
                        title: ButtonTitle.MakePayment,
                        text: this.$t('components.commonActions.makePayment'),
                        iconSrc: 'images/components/commonActions/Payment.svg',
                        invertIconColor: false,
                        goto: RoutePaths.MAKE_PAYMENT,
                        dataTestId: 'common-action-button-make-payment',
                    },
                    {
                        title: ButtonTitle.BalanceTransfer,
                        text: this.$t('components.commonActions.cardBalance'),
                        iconSrc: 'images/components/commonActions/BalanceTransfer.svg',
                        invertIconColor: false,
                        goto: RoutePaths.BALANCE_TRANSFER_REQUEST,
                        dataTestId: 'common-action-button-balance-transfer',
                    },
                    {
                        title: ButtonTitle.CashOut,
                        text: this.$t('components.commonActions.cashOut'),
                        iconSrc: 'images/components/commonActions/Cash.svg',
                        invertIconColor: false,
                        goto: RoutePaths.CASH_OUT_HISTORY,
                        dataTestId: 'common-action-button-cash-out',
                    },
                    {
                        title: ButtonTitle.ActivateBankTransfer,
                        text: this.$t('components.commonActions.activateBankTransfer'),
                        iconSrc: 'images/components/commonActions/BankAccount.svg',
                        invertIconColor: false,
                        onClick: this.onClickActivateBankTransfer,
                        dataTestId: 'common-action-button-activate-bank-transfer',
                    },
                ],
            }
        },
        computed: {
            showCashOutFeature() {
                return this.overviewStore.showCashOutFeature
            },
            showBalanceTransferFeature() {
                return this.overviewStore.showBalanceTransferFeature
            },
            showActivateBankTransferFeature() {
                return this.upgradeOffersStore.upgradeOfferToDisplay
            },
        },
        methods: {
            handleButtonClick(button) {
                this.$logEvent('click_button_common_action', { button })
                const { onClick, goto } = button
                // Not used right now but allows grater flexibility for buttons to do anything,
                // not just change routes
                if (typeof onClick === 'function') {
                    onClick(...arguments)
                }
                // Go to the specified route
                if (typeof goto === 'string') {
                    this.$router.push(goto)
                }
            },
            async onClickActivateBankTransfer() {
                this.$logEvent('click_button_activate_bank_transfer', {
                    offer: this.upgradeOffersStore.upgradeOfferToDisplay,
                })
                return await this.$router.push({
                    name: RouteNames.AVEN_HOME_CARD_UPGRADE,
                    params: {
                        variant: AvenHomeCardUpgradeVariant.lineSize,
                        originatingSourceName: 'commonActionsActivateBankTransfer',
                    },
                })
            },
            showCondition(buttonTitle) {
                switch (buttonTitle) {
                    case ButtonTitle.MakePayment:
                        return true
                    case ButtonTitle.BalanceTransfer:
                        return this.showBalanceTransferFeature
                    case ButtonTitle.CashOut:
                        return this.showCashOutFeature
                    case ButtonTitle.ActivateBankTransfer:
                        return this.showActivateBankTransferFeature
                    default:
                        return false
                }
            },
        },
    }
</script>
