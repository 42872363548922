import payItForwardMixin from '@/mixins/payItForwardMixin'
import { useOverviewStore } from '@/store/overviewStore'
import { PifRewardType } from 'aven_types'

export default {
    mixins: [payItForwardMixin],
    data() {
        return {
            showInfoModal: false,
        }
    },
    computed: {
        pifModalTitle() {
            switch (this.pifRewardType) {
                case PifRewardType.ucc100UsdReferral:
                    return this.$t('pages.payItForwardInfoModal.ucc100UsdReferral.pageTitle').toString()
                default:
                    return this.$t('pages.payItForwardInfoModal.title').toString()
            }
        },
        pifModalBody() {
            return this.$t(`pages.payItForwardInfoModal.${this.pifRewardType}.content`, { pifBonus: this.toFormattedUSDNoCents(this.payItForwardMaxAmount) })
        },
        pifModalButtonText() {
            return this.$t('pages.payItForwardInfoModal.close')
        },
        pifRewardType(): PifRewardType {
            return useOverviewStore().pifRewardType
        },
    },
    methods: {
        openInfoModal: function () {
            this.showInfoModal = true
        },
        closeInfoModal: function () {
            this.showInfoModal = false
        },
    },
}
