<template>
    <div v-if="isDelinquent && !isAccountChargedOff">
        <div
            class="banner banner-delinquent m-0 mb-2"
            data-testid="what-is-due-banner"
        >
            <div class="d-md-flex align-items-center justify-content-around banner-container my-1 mx-auto">
                <p class="mb-0">
                    {{ $t(`pages.WhatIsDue.banner.mainContent`, { amountPastDue: toFormattedUSD(this.amountPastDue) }) }}
                    <router-link
                        class="text-underline"
                        :to="{
                            name: RouteNames.MAKE_PAYMENT,
                            query: {
                                schedulePayment: true,
                            },
                        }"
                        data-testid="what-is-due-banner-pay-now-link"
                    >
                        {{ $t(`pages.WhatIsDue.banner.payNow`) }}
                    </router-link>
                </p>
            </div>
        </div>

        <!-- DUE DATE -->
        <div class="d-flex justify-content-between mb-1">
            <p class="mb-0">
                {{ $t('pages.WhatIsDue.dueBy') }}
            </p>
            <p class="mb-0">
                {{ paymentDueDateFormatted }}
            </p>
        </div>

        <!-- MINIMUM DUE (incl. past due) -->
        <div class="d-flex justify-content-between mb-1">
            <p class="mb-0">
                {{ $t('pages.WhatIsDue.dqMinimumDue') }}
            </p>
            <p class="mb-0">
                {{ minimumDue | dollar }}
            </p>
        </div>

        <!-- SCHEDULED PAYMENT / AUTOPAY CARD -->
        <div
            v-if="nextAutopayOrScheduledPayment"
            class="gray-div mb-1 mt-2"
        >
            <p
                v-if="nextAutopayOrScheduledPayment.isAutopay"
                class="mb-0 text-gray-400"
            >
                {{
                    nextAutopayOrScheduledPayment.paymentAmount > 0
                        ? $t('pages.WhatIsDue.nextAutopayPayment', {
                            autopayAmount: nextAutopayOrScheduledPayment.paymentAmount,
                            formattedAutopayDate: nextAutopayOrScheduledPayment.scheduledPaymentDate,
                        })
                        : $t('pages.WhatIsDue.nextAutopayPaymentNoAmount', { formattedAutopayDate: nextAutopayOrScheduledPayment.scheduledPaymentDate })
                }}
            </p>
            <p
                v-else
                class="mb-0 text-gray-400"
            >
                {{
                    $t('pages.WhatIsDue.nextScheduledPayment', {
                        scheduledPaymentAmount: nextAutopayOrScheduledPayment.paymentAmount,
                        formattedPaymentDate: nextAutopayOrScheduledPayment.scheduledPaymentDate,
                    })
                }}
            </p>
        </div>

        <!-- DETAILS AND PAY NOW BUTTONS -->
        <div class="row g-2">
            <div class="col pe-1 position-relative d-flex">
                <button
                    class="btn btn-secondary mt-1 w-100"
                    @click="onDetailsClick"
                >
                    {{ $t('pages.WhatIsDue.details') }}
                </button>
            </div>
            <div class="col pe-1 position-relative d-flex">
                <router-link
                    :to="{
                        name: RouteNames.MAKE_PAYMENT,
                        query: {
                            schedulePayment: true,
                        },
                    }"
                    class="btn btn-primary mt-1 w-100"
                >
                    {{ $t('pages.WhatIsDue.payNow') }}
                </router-link>
            </div>
        </div>
    </div>
    <div v-else-if="hasMinimumDue">
        <!-- DUE DATE -->
        <div class="d-flex justify-content-between">
            <p class="mb-0">
                {{ $t('pages.WhatIsDue.dueBy') }}
            </p>
            <p class="mb-0">
                {{ paymentDueDateFormatted }}
            </p>
        </div>

        <!-- STATEMENT BALANCE -->
        <div class="d-flex justify-content-between">
            <p class="mb-0">
                {{ $t('pages.WhatIsDue.statementBalance') }}
            </p>
            <p class="mb-0">
                {{ statementRemainingBalance | dollar }}
            </p>
        </div>

        <!-- MINIMUM DUE -->
        <div class="d-flex justify-content-between">
            <p class="mb-0">
                {{ $t('pages.WhatIsDue.minimumDue') }}
            </p>
            <p class="mb-0">
                {{ minimumDue | dollar }}
            </p>
        </div>

        <div
            v-if="shouldDisplayUpgradeOffer"
            class="tw-bg-dark-green tw-text-white tw-rounded-xl tw-flex tw-align-items-center tw-py-4 tw-px-6 tw-mt-4"
            @click="onUpgradeClick"
            @keydown.enter="onUpgradeClick"
        >
            <div>
                <span>{{ $t('pages.WhatIsDue.uccCardholderUpgrade.title.part1') }}</span>
                <span
                    v-html="$t('pages.WhatIsDue.uccCardholderUpgrade.title.part2', { formattedMonthlyPayment: toFormattedUSD(this.upgradeLowestInstallmentMonthlyPayment) })"
                    @click.stop="onToggleDisclosures"
                    @keydown.enter.stop="onToggleDisclosures"
                />
                <span v-html="$t('pages.WhatIsDue.uccCardholderUpgrade.title.part3')" />
            </div>
            <img
                class="tw-ms-2"
                src="@/assets/navigation-right-arrow.svg"
                alt="continue arrow"
            >
        </div>

        <!-- SCHEDULED PAYMENT / AUTOPAY CARD -->
        <div
            v-if="nextAutopayOrScheduledPayment"
            class="gray-div mb-1 mt-2"
        >
            <p
                v-if="nextAutopayOrScheduledPayment.isAutopay"
                class="mb-0 text-gray-400"
            >
                {{
                    nextAutopayOrScheduledPayment.paymentAmount > 0
                        ? $t('pages.WhatIsDue.nextAutopayPayment', {
                            autopayAmount: nextAutopayOrScheduledPayment.paymentAmount,
                            formattedAutopayDate: nextAutopayOrScheduledPayment.scheduledPaymentDate,
                        })
                        : $t('pages.WhatIsDue.nextAutopayPaymentNoAmount', { formattedAutopayDate: nextAutopayOrScheduledPayment.scheduledPaymentDate })
                }}
            </p>
            <p
                v-else
                class="mb-0 text-gray-400"
            >
                {{
                    $t('pages.WhatIsDue.nextScheduledPayment', {
                        scheduledPaymentAmount: nextAutopayOrScheduledPayment.paymentAmount,
                        formattedPaymentDate: nextAutopayOrScheduledPayment.scheduledPaymentDate,
                    })
                }}
            </p>
        </div>

        <!-- DETAILS AND PAY NOW BUTTONS -->
        <div class="row g-2">
            <div class="col pe-1 position-relative d-flex">
                <button
                    class="btn btn-secondary mt-1 w-100"
                    @click="onDetailsClick"
                >
                    {{ $t('pages.WhatIsDue.details') }}
                </button>
            </div>
            <div class="col pe-1 position-relative d-flex">
                <router-link
                    to="makePayment"
                    class="btn btn-primary mt-1 w-100"
                >
                    {{ $t('pages.WhatIsDue.payNow') }}
                </router-link>
            </div>
        </div>
        <modal-dialog
            :show="displayDisclosures"
            @close="onToggleDisclosures"
        >
            <div class="tw-px-4">
                <ol class="tw-text-gray-400 tw-p-0 tw-text-[12px]">
                    <li
                        value="1"
                        v-html="upgradeDisclosures"
                    />
                </ol>
            </div>
        </modal-dialog>
    </div>
    <div v-else>
        <!-- STATEMENT BALANCE -->
        <div class="d-flex justify-content-between mb-1">
            <p class="mb-0">
                {{ $t('pages.WhatIsDue.remainingStatementBalance') }}
            </p>
            <p class="mb-0">
                {{ statementRemainingBalance | dollar }}
            </p>
        </div>

        <!-- NEXT STATEMENT DATE -->
        <div class="d-flex justify-content-between mb-1">
            <p class="mb-0">
                {{ $t('pages.WhatIsDue.nextStatementDate') }}
            </p>
            <p class="mb-0">
                {{ nextStateDateFormatted }}
            </p>
        </div>

        <!-- SCHEDULED PAYMENT / AUTOPAY CARD -->
        <div
            v-if="nextAutopayOrScheduledPayment"
            class="gray-div mb-1 mt-2"
        >
            <p
                v-if="nextAutopayOrScheduledPayment.isAutopay"
                class="mb-0 text-gray-400"
            >
                {{
                    nextAutopayOrScheduledPayment.paymentAmount > 0
                        ? $t('pages.WhatIsDue.nextAutopayPayment', {
                            autopayAmount: nextAutopayOrScheduledPayment.paymentAmount,
                            formattedAutopayDate: nextAutopayOrScheduledPayment.scheduledPaymentDate,
                        })
                        : $t('pages.WhatIsDue.nextAutopayPaymentNoAmount', { formattedAutopayDate: nextAutopayOrScheduledPayment.scheduledPaymentDate })
                }}
            </p>
            <p
                v-else
                class="mb-0 text-gray-400"
            >
                {{
                    $t('pages.WhatIsDue.nextScheduledPayment', {
                        scheduledPaymentAmount: nextAutopayOrScheduledPayment.paymentAmount,
                        formattedPaymentDate: nextAutopayOrScheduledPayment.scheduledPaymentDate,
                    })
                }}
            </p>
        </div>

        <!-- DETAILS AND PAY NOW BUTTONS -->
        <div class="row g-2">
            <div class="col pe-1 position-relative d-flex">
                <button
                    class="btn btn-secondary mt-1 w-100"
                    @click="onDetailsClick"
                >
                    {{ $t('pages.WhatIsDue.details') }}
                </button>
            </div>
            <div class="col pe-1 position-relative d-flex">
                <router-link
                    to="makePayment"
                    class="btn btn-secondary mt-1 w-100"
                >
                    {{ $t('pages.WhatIsDue.makePayment') }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import { logger } from '../utils/logger'
    import generic from '../utils/generic'
    import { DISPLAY_DATE_FORMAT, NYC_TIMEZONE } from '../data/constants'
    import { toFormattedUSD } from '../mixins/format'
    import { useOverviewStore } from '@/store/overviewStore'
    import { RouteNames } from '@/routes/router.types'
    import { AutopayCategory } from '@/store/overviewStore.types'
    import router from '@/routes/router'
    import { useUpgradeOffersStore } from '@/store/upgradeOffersStore'
    import ModalDialog from 'aven_shared/src/components/ModalDialog.vue'
    import { AvenHomeCardUpgradeVariant } from '@/views/avenHomeCardUpgrade.types'

    /**
     * If the lowest upgrade installment monthly payment is below this dollar amount, then we'll
     * use this dollar amount instead to in the computed value shouldDisplayUpgradeOffer.
     */
    const MIN_UPGRADE_LOWEST_INSTALLMENT_MONTHLY_PAYMENT_DOLLARS = 25

    export default {
        name: 'WhatIsDue',
        components: { ModalDialog },
        data() {
            return {
                displayDisclosures: false,
                shouldDisplayUpgradeOffer: null,
            }
        },
        methods: {
            toFormattedUSD,
            formatDateUtc(date) {
                return generic.utcToTimeZone(date, NYC_TIMEZONE, DISPLAY_DATE_FORMAT)
            },
            async onUpgradeClick() {
                this.$logEvent('click_aven_my_ucc_cardholder_to_home_upgrade_reduce_monthly_payment', {
                    offer: this.upgradeOfferToDisplay,
                })
                return await router.push({
                    name: RouteNames.AVEN_HOME_CARD_UPGRADE,
                    params: {
                        variant: AvenHomeCardUpgradeVariant.reduceMonthlyPayment,
                        originatingSourceName: 'reduceMonthlyPaymentCard',
                    },
                })
            },
            onToggleDisclosures() {
                this.$logEvent('click_aven_my_ucc_cardholder_to_home_upgrade_reduce_monthly_payment_toggle_disclosures', {
                    offer: this.upgradeOfferToDisplay,
                })
                this.displayDisclosures = !this.displayDisclosures
            },
            async onDetailsClick() {
                this.$logEvent('click_aven_my_what_is_due_details', {
                    amountPastDue: this.amountPastDue,
                    minimumDue: this.minimumDue,
                    statementBalance: this.statementRemainingBalance,
                })

                await this.$router.replace({ name: RouteNames.STATEMENTS })
            },
            /**
             * shouldDisplayUpgradeOffer is based on async loading values. If those values are already loaded
             * when this component is mounted, then this method will cause the watcher on shouldDisplayUpgradeOffer
             * to send its analytics event if shouldDisplayUpgradeOffer === true. However, if those values load
             * only after mounted, the watcher on computedShouldDisplayUpgradeOffer will cause shouldDisplayUpgradeOffer,
             * thus storing the analytics event w/ in the watcher on shouldDisplayUpgradeOffer.
             */
            setShouldDisplayUpgradeOffer() {
                this.shouldDisplayUpgradeOffer = this.computedShouldDisplayUpgradeOffer
            },
        },
        computed: {
            paymentDueDateFormatted() {
                if (this.paymentDueDate) {
                    logger.info(`convert payment due date from ${this.paymentDueDate}`)
                    return generic.utcToTimeZone(this.paymentDueDate, NYC_TIMEZONE, DISPLAY_DATE_FORMAT)
                }
                return ''
            },
            nextStateDateFormatted() {
                if (this.nextStatementDate) {
                    logger.info('convert next statement date')
                    return generic.utcToTimeZone(this.nextStatementDate)
                }
                return ''
            },
            hasMinimumDue() {
                return this.minimumDue > 0
            },
            amountPastDue() {
                return useOverviewStore().amountPastDue
            },
            minimumDue() {
                return useOverviewStore().minimumDue
            },
            statementRemainingBalance() {
                return useOverviewStore().statementRemainingBalance
            },
            paymentDueDate() {
                return useOverviewStore().paymentDueDate
            },
            autopayNextPaymentDisplayDate() {
                return useOverviewStore().autopayNextPaymentDisplayDate
            },
            nextStatementDate() {
                return useOverviewStore().nextStatementDate
            },
            isDelinquent() {
                return useOverviewStore().daysDelinquent > 0
            },
            isAccountChargedOff() {
                return useOverviewStore().isAccountChargedOff
            },
            scheduledPayments() {
                return useOverviewStore().scheduledPayments
            },
            autoPaySetting() {
                return useOverviewStore().autoPaySetting
            },
            upgradeOfferToDisplay() {
                return useUpgradeOffersStore().upgradeOfferToDisplay
            },
            upgradeLowestInstallmentMonthlyPayment() {
                return useUpgradeOffersStore().upgradeLowestInstallmentMonthlyPayment
            },
            computedShouldDisplayUpgradeOffer() {
                return (
                    this.hasMinimumDue &&
                    this.upgradeOfferToDisplay &&
                    this.upgradeLowestInstallmentMonthlyPayment &&
                    Math.max(this.upgradeLowestInstallmentMonthlyPayment, MIN_UPGRADE_LOWEST_INSTALLMENT_MONTHLY_PAYMENT_DOLLARS) < this.minimumDue
                )
            },
            upgradeDisclosures() {
                return useUpgradeOffersStore().getUpgradeDisclosures(AvenHomeCardUpgradeVariant.reduceMonthlyPayment)
            },
            nextAutopayPayment() {
                if (!this.autoPaySetting.isEnabled) {
                    return null
                }
                let paymentAmount
                if (this.autoPaySetting.autoPayDetails.autoPayCategory === AutopayCategory.MINIMUM_DUE) {
                    paymentAmount = this.minimumDue
                } else if (this.autoPaySetting.autoPayDetails.autoPayCategory === AutopayCategory.STATEMENT_BALANCE) {
                    paymentAmount = this.statementRemainingBalance
                } else if (this.autoPaySetting.autoPayDetails.autoPayCategory === AutopayCategory.CUSTOM_AMOUNT) {
                    paymentAmount = this.autoPaySetting.autoPayDetails.customAmount
                }
                return {
                    paymentAmount,
                    scheduledPaymentDate: this.formatDateUtc(this.autopayNextPaymentDisplayDate),
                    isAutopay: true,
                }
            },
            nextScheduledPayment() {
                if (this.scheduledPayments.length === 0) {
                    return null
                }
                return {
                    paymentAmount: this.scheduledPayments[0].paymentAmount,
                    scheduledPaymentDate: this.formatDateUtc(this.scheduledPayments[0].scheduledPaymentDate),
                    isAutopay: false,
                }
            },
            nextAutopayOrScheduledPayment() {
                if (!this.nextScheduledPayment && !this.nextAutopayPayment) {
                    return null
                } else if (!this.nextScheduledPayment) {
                    return this.nextAutopayPayment
                } else if (!this.nextAutopayPayment) {
                    return this.nextScheduledPayment
                } else {
                    if (this.nextAutopayPayment.scheduledPaymentDate < this.nextScheduledPayment.scheduledPaymentDate) {
                        return this.nextAutopayPayment
                    } else {
                        return this.nextScheduledPayment
                    }
                }
            },
            RouteNames() {
                return RouteNames
            },
        },
        mounted() {
            this.setShouldDisplayUpgradeOffer()
        },
        watch: {
            shouldDisplayUpgradeOffer(newValue, oldValue) {
                if (newValue && newValue !== oldValue) {
                    this.$logEvent('view_aven_my_ucc_cardholder_to_home_upgrade_reduce_monthly_payment', {
                        offer: this.upgradeOfferToDisplay,
                    })
                }
            },
            computedShouldDisplayUpgradeOffer() {
                this.setShouldDisplayUpgradeOffer()
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/components/transactionRow';
    @import 'node_modules/aven_shared/src/styles/components/banner';

    .banner {
        max-width: 640px;
        margin: 0;
    }

    .gray-div {
        background-color: $gray-100;
        border-radius: 8px;
        padding: 16px;
    }
</style>
