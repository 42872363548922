<template>
    <div
        class="dashboard-container"
        :class="{ 'bg-light': isBgLight }"
    >
        <div
            v-show="showBanners"
            class="account-banner-container"
        >
            <account-activation-banner @show="onBannerShow" />
            <account-status-banner @show="onBannerShow" />
            <cash-v2-activation-banner @show="onBannerShow" />
        </div>
        <modal
            :show="showIdleWarningModal"
            :title="$t('global.idleWarning.title')"
            @close="closeIdleWarningModal"
        >
            <p class="text-center mb-3">
                {{ $t('global.idleWarning.message') }}
            </p>
            <base-button
                class="mt-3"
                @click="logout('click_logout')"
            >
                {{ $t('global.idleWarning.logout') }}
            </base-button>
            <base-button
                class="mt-2"
                @click="closeIdleWarningModal"
                button-classes="btn btn-secondary"
            >
                {{ $t('global.idleWarning.stay') }}
            </base-button>
        </modal>
        <div class="dashboard-layout">
            <nav v-if="showNav">
                <img
                    class="logo"
                    src="@/assets/images/global/aven.svg"
                    width="71"
                    height="27"
                    alt="Aven"
                >
                <div
                    v-for="navigationTab in navigationTabs"
                    :key="navigationTab.tabRoutePath"
                    class="nav-item"
                    @click="onClickTab(navigationTab.tabRoutePath)"
                    @keydown.enter="navigation !== navigationTab.tabRoutePath ? $router.push(navigationTab.tabRoutePath) : null"
                    :data-testid="navigationTab.tabDataTestId"
                >
                    <div class="icon">
                        <img
                            v-show="navigation === navigationTab.tabRoutePath"
                            :src="navigationTab.tabIconActive"
                            :alt="navigationTab.tabName + ' ' + $t('accessibility.active')"
                        >
                        <img
                            v-show="navigation !== navigationTab.tabRoutePath"
                            :src="navigationTab.tabIconInactive"
                            :alt="navigationTab.tabName"
                        >
                    </div>
                    <p
                        :class="{
                            'fw-bold': navigation === navigationTab.tabRoutePath,
                        }"
                    >
                        {{ navigationTab.tabName }}
                    </p>
                </div>
            </nav>
            <!--https://stackoverflow.com/questions/61158306/vue-js-mounted-method-called-twice-->
            <!--tldr: using v-if will cause double mount-->
            <error-view
                v-show="showFullScreenError"
                :title="$t('global.errors.title')"
                :description="$t('global.errors.generic')"
                :cta="$t('global.cta.retry')"
                @click="retryOnError"
            />
            <loading-indicator v-show="showFullScreenLoadingIndicator && !showFullScreenError" />

            <main
                v-show="!showFullScreenLoadingIndicator && !showFullScreenError"
                :class="{
                    'nav-visible': showNav,
                }"
            >
                <slot />
            </main>
        </div>
    </div>
</template>

<script>
    import LoadingIndicator from '@/components/LoadingIndicator'
    import AccountStatusBanner from '@/components/AccountStatusBanner'
    import Modal from '@/components/Modal'
    import BaseButton from '@/components/base/BaseButton'
    import ErrorView from '@/components/ErrorView'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import throttle from 'lodash/throttle'
    import { logger } from '@/utils/logger'
    import AccountActivationBanner from '@/components/AccountActivationBanner'
    import { useGlobalUiStore } from '@/store/globalUiStore'
    import { useOverviewStore } from '@/store/overviewStore'
    import { useDeviceInfoStore } from '@/store/deviceInfoStore'
    import { RoutePaths } from '@/routes/router.types'
    import { DebtProductAndMetaDataSet } from '@/services/avenAppApi'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import CashV2ActivationBanner from '@/components/CashV2ActivationBanner.vue'
    import { navigationTabRouteNames, navigationTabRoutePaths, navigationTabs } from '@/utils/navigationTabs'
    import assert from 'assert'
    import { useRewardsStore } from '@/store/rewardsStore'

    const USER_ACTIVITY_THROTTLER_TIME_MSEC = 500
    // idle auto logout threshold in milliseconds, 1hr on development, 5min on everything else
    const AUTOLOGOUT_THRESHOLD_MSEC = process.env.VUE_APP_NODE_ENV === 'development' ? 60 * 60 * 1000 : 5 * 60 * 1000
    const PAGES_TO_HIDE_NAV = [
        RoutePaths.PAY_IT_FORWARD_CONTACT_LIST,
        RoutePaths.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_SUBMITTED,
        'MailerActivation' /* Todo Is MailerActivation relevant any longer? Page w/ that name doesn't seem to exist */,
        RoutePaths.AVEN_HOME_CARD_UPGRADE,
    ]

    export default {
        name: 'DashboardLayout',
        props: {
            navigation: {
                type: String,
                validator: function (value) {
                    return [...navigationTabRoutePaths, RoutePaths.PAY_IT_FORWARD_CONTACT_LIST, RoutePaths.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_SUBMITTED, ''].includes(value)
                },
            },
        },
        components: {
            CashV2ActivationBanner,
            'loading-indicator': LoadingIndicator,
            AccountActivationBanner,
            AccountStatusBanner,
            Modal,
            BaseButton,
            ErrorView,
        },
        computed: {
            showNav: function () {
                const supportsWebNav =
                    // If Aven My is run in a native app that asserts it can use the web navigation bar
                    // then obviously the runtime supports web nav.
                    useDeviceInfoStore().shouldUseWebNavigationBar ||
                    // isWebView is only true when Aven My is running in a native app shell, and even then
                    // only in some circumstances. Whenever Aven My is *not* running in a native app shell,
                    // we use web nav.
                    !this.isWebView ||
                    // On Android we exclusively use web nav when Aven My is displayed via a single web view
                    // Todo This *might* be a completely defunct condition. Should look into it
                    (this.platform === 'android' && this.isSingleWebView)
                const isPageThatHidesNav = PAGES_TO_HIDE_NAV.includes(this.navigation)
                const isPreActivationCashOutFlow = appSessionStorage.getItem(localStorageKey.preActivationCashOutFlow)
                return supportsWebNav && !isPageThatHidesNav && !isPreActivationCashOutFlow
            },
            isOverViewInitialized() {
                return useOverviewStore().isOverViewInitialized
            },
            showFullScreenLoadingIndicator: function () {
                return this.loading && !this.isOverViewInitialized
            },
            // only present error view if data failed on initial loading
            // TODO: on refresh, we might want to display a ephemeral notice, so user can drag to refresh again if desired.
            showFullScreenError() {
                return this.error.length > 0 && !this.isOverViewInitialized
            },
            idleWarningThreshold() {
                return AUTOLOGOUT_THRESHOLD_MSEC - 30000 // msec
            },
            isBgLight() {
                return navigationTabRoutePaths.includes(this.navigation)
            },
            loading() {
                return useGlobalUiStore().loading
            },
            error() {
                return useGlobalUiStore().error
            },
            currentRootPathOrDefault() {
                return useGlobalUiStore().currentRootPathOrDefault
            },
            platform() {
                return useDeviceInfoStore().platform
            },
            isWebView() {
                return useDeviceInfoStore().isWebView
            },
            isSingleWebView() {
                return useDeviceInfoStore().isSingleWebView
            },
            showBanners() {
                return this.shownBanners.length !== 0
            },
        },
        data() {
            return {
                idleWarningTimeout: null,
                logoutTimeout: null,
                showIdleWarningModal: false,
                hasError: false,
                userActions: ['mousemove', 'scroll', 'keydown', 'wheel', 'touchstart'],
                shownBanners: [],
                navigationTabs,
                RoutePaths,
            }
        },
        mounted: async function () {
            logger.info(`--- Dashboard.${this.currentRootPathOrDefault} mounted ---`)

            if (!this.isOverViewInitialized) {
                logger.info(`--- Dashboard.${this.currentRootPathOrDefault} invoking updateAccountOverview ---`)
                await this.getAccountOverview()
            }
            // when not in webview, aka native apps
            // start monitor userAction events, defined above in data
            // create timer for displaying warning
            // create timer for auto logout
            if (!this.isWebView) {
                this.startMonitorUserAction()
                this.scheduleIdleWarningPopup()
                this.scheduleAutoLogout()
            }
            // when native tab bar button is clicked, this function will be invoked
            window.switchTab = this.switchTab

            // We'll preload rewards for a snappy experience when navigating to the rewards tab. Any
            // changes we'll load in the background when the tab comes into view.
            await useRewardsStore().tryRefreshAllRewards()
        },
        destroyed: function () {
            logger.info(`--- Dashboard.${this.currentRootPathOrDefault} destroyed ---`)
            this.stopMonitoringUserAction()
            clearTimeout(this.logoutTimeout)
            clearTimeout(this.idleWarningTimeout)
        },
        methods: {
            onBannerShow: function (bannerName, status) {
                logger.info(`onBannerShow: ${bannerName} ${status}`)
                if (status === 'show') {
                    if (this.shownBanners.some((banner) => banner === bannerName)) {
                        return
                    }
                    this.shownBanners.push(bannerName)
                } else {
                    if (this.shownBanners.some((banner) => banner === bannerName)) {
                        this.shownBanners = this.shownBanners.filter((banner) => banner !== bannerName)
                    }
                }
            },
            retryOnError: async function () {
                await this.getAccountOverview()
            },
            getAccountOverview: async function () {
                try {
                    await useOverviewStore().updateAccountOverview({
                        debtProductAndMetaDataSet: DebtProductAndMetaDataSet.all,
                    })
                } catch (error) {
                    ApiErrorHandler(error)
                }
            },
            // listen to window events that indicates user is using the app
            // event handling is throttled with interval of 250 msec
            // every user interaction event, reset both idle warning modal timeout and auto logout timeout
            // when idle warning expires, we stop monitoring user interaction on the window.
            // auto logout will occur if user doesn't interact with the warning modal
            // auto logout timeout      |-------------AUTOLOGOUT_THRESHOLD_MSEC----------------|
            // idle warning timeout     |-----(AUTOLOGOUT_THRESHOLD_MSEC - 30000 msec)----|
            startMonitorUserAction: function () {
                // dev debugging only -- console.log('startMonitorUserAction')
                this.userActions.forEach((action) => window.addEventListener(action, this.throttledUserActivityHandler))
            },
            // stops listening for window events
            stopMonitoringUserAction: function () {
                this.userActions.forEach((action) => window.removeEventListener(action, this.throttledUserActivityHandler))
                // dev debugging only -- console.log('stopMonitorUserAction')
            },
            // cancel idle warning timeout
            // schedule new idle warning timeout which presents after (AUTOLOGOUT_THRESHOLD_MSEC - 30000 msec)
            scheduleIdleWarningPopup: function () {
                // dev debugging only -- console.log(`cancel - IdleWarningPopup`)
                clearTimeout(this.idleWarningTimeout)
                this.idleWarningTimeout = setTimeout(() => {
                    this.presentIdleWarningModal()
                }, this.idleWarningThreshold)
                // dev debugging only -- console.log(`schedule - IdleWarningPopup`)
            },
            // cancel auto logout timeout
            // schedule new uto logout timeout that occurs after AUTOLOGOUT_THRESHOLD_MSEC
            scheduleAutoLogout: function () {
                // dev debugging only -- console.log(`cancel - AutoLogout`)
                clearTimeout(this.logoutTimeout)
                this.logoutTimeout = setTimeout(() => {
                    this.logout('event_auto_logout')
                }, AUTOLOGOUT_THRESHOLD_MSEC)
                // dev debugging only -- console.log('schedule - AutoLogout')
            },
            // show idle warning modal
            // stop monitoring user interactions
            presentIdleWarningModal() {
                logger.info(`presentIdleWarningModal`)
                this.showIdleWarningModal = true
                this.stopMonitoringUserAction()
            },
            // handles "X" button or "Stay" button on the idle warning modal
            // closing idle warning, means user intends to continue their session
            // start monitoring user interaction
            // reschedules idle warning timeout and auto logout timeout
            closeIdleWarningModal: function () {
                logger.info(`closeIdleWarningModal`)
                clearTimeout(this.logoutTimeout)
                clearTimeout(this.idleWarningTimeout)
                this.startMonitorUserAction()
                this.scheduleIdleWarningPopup()
                this.scheduleAutoLogout()
                this.showIdleWarningModal = false
            },
            // handles "Logout" button on idle warning modal
            // also handles auto logout
            // clear all scheduled timeouts
            // stope monitoring user interactions
            logout: function (eventName) {
                window.logEvent(eventName)
                clearTimeout(this.logoutTimeout)
                clearTimeout(this.idleWarningTimeout)
                this.stopMonitoringUserAction()
                logger.info(`mSite(${this.currentRootPathOrDefault}), idle logout occurred`)
                this.$router.push(RoutePaths.LOGOUT)
            },
            // called by the system whenever a userInteraction event occurs
            // reschedule both idle warning timeout and auto logout timeout
            throttledUserActivityHandler: throttle(function () {
                this.scheduleIdleWarningPopup()
                this.scheduleAutoLogout()
            }, USER_ACTIVITY_THROTTLER_TIME_MSEC),
            onClickTab: function (tabRoutePath) {
                if (this.navigation !== tabRoutePath) {
                    this.$router.push(tabRoutePath)
                    useGlobalUiStore().setCurrentRootPath(tabRoutePath)
                }
            },
            // ==== invoked by native code ====
            switchTab: function (tabName) {
                logger.info(`switchTab: ${tabName}`)
                assert(navigationTabRouteNames.includes(tabName), `Tried to switch tab to ${tabName}, but not included in navigationTabRouteNames=${JSON.stringify(navigationTabRouteNames)}`)
                this.onClickTab(navigationTabs.find((tab) => tab.tabRouteName === tabName).tabRoutePath)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/layouts/dashboard';
    @import 'node_modules/aven_shared/src/styles/components/appHeader';
    @import 'node_modules/aven_shared/src/styles/components/appBase';
</style>
