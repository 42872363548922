import { computed, ref, watch } from 'vue'
import { HumanReadableLocationForPromotion, PromotionName, PromotionStatus } from 'aven_types'
import { logger } from '@/utils/logger'
import { logEvent } from '@/services/http-client'
import { claimQuickPromotionReward, getQuickPromotionHumanReadableLocationForCustomer, getQuickPromotionStatus } from '@/services/api'
import { javascriptNativeInterop, NativeAsyncFunction } from '@/services/javascriptNativeInterop'
import { useRouter } from 'vue-router/composables'
import { RoutePaths } from '@/routes/router.types'
import { i18n } from '@/utils/i18n'

interface EventsForPromotion {
    claimClick: string
    viewNativeShare: string
    viewCopyLink: string
    shareClick: string
    shareEvent: string
    copyClick: string
    copyEvent: string
    claimFeedUnitClick: string
}

const getEventsForPromotion = (promotionName: PromotionName): EventsForPromotion | undefined => {
    switch (promotionName) {
        case PromotionName.costcoFreeMembership:
            return {
                claimClick: 'click_claim_costco_rewards',
                viewNativeShare: 'view_costco_rewards_page_claim_success_native_share_enabled',
                viewCopyLink: 'view_costco_rewards_page_claim_success_copy_link_enabled',
                shareClick: 'click_share_with_friends_costco_rewards',
                shareEvent: 'event_native_share_result_costco_rewards',
                copyClick: 'click_costco_rewards_copy_link',
                copyEvent: 'event_costco_rewards_copy_link',
                claimFeedUnitClick: 'click_claim_costco_rewards_feed_unit',
            }
        case PromotionName.restaurants7Percent:
            return {
                claimClick: 'click_claim_restaurants_7_promotion',
                viewNativeShare: 'view_restaurants_7_promotion_page_claim_success_native_share_enabled',
                viewCopyLink: 'view_restaurants_7_promotion_page_claim_success_copy_link_enabled',
                shareClick: 'click_share_with_friends_restaurants_7_promotion',
                shareEvent: 'event_native_share_result_restaurants_7_promotion',
                copyClick: 'click_restaurants_7_promotion_copy_link',
                copyEvent: 'event_restaurants_7_promotion_copy_link',
                claimFeedUnitClick: 'click_claim_restaurants_7_promotion_feed_unit',
            }
        case PromotionName.amazonPrime:
            return {
                claimClick: 'click_claim_amazon_prime_promotion',
                viewNativeShare: 'view_amazon_prime_promotion_page_claim_success_native_share_enabled',
                viewCopyLink: 'view_amazon_prime_promotion_page_claim_success_copy_link_enabled',
                shareClick: 'click_share_with_friends_amazon_prime_promotion',
                shareEvent: 'event_amazon_prime_promotion_copy_link',
                copyClick: 'click_amazon_prime_promotion_copy_link',
                copyEvent: 'event_amazon_prime_promotion_copy_link',
                claimFeedUnitClick: 'click_claim_amazon_prime_promotion_feed_unit',
            }
        case PromotionName.gasAndGroceries7Percent:
            return {
                claimClick: 'click_claim_gas_and_groceries_7_promotion',
                viewNativeShare: 'view_gas_and_groceries_7_promotion_page_claim_success_native_share_enabled',
                viewCopyLink: 'view_gas_and_groceries_7_promotion_page_claim_success_copy_link_enabled',
                shareClick: 'click_share_with_friends_gas_and_groceries_7_promotion',
                shareEvent: 'event_native_share_result_gas_and_groceries_7_promotion',
                copyClick: 'click_gas_and_groceries_7_promotion_copy_link',
                copyEvent: 'event_gas_and_groceries_7_promotion_copy_link',
                claimFeedUnitClick: 'click_claim_gas_and_groceries_7_promotion_feed_unit',
            }
        default:
            logger.error(`Missing click event for promotionName ${promotionName}`)
            return undefined
    }
}

export const useQuickPromotionCommons = (promotionName: PromotionName) => {
    // successRef is null if the claim has not been attempted yet, true if successful, false if failed
    const successRef = ref<boolean | null>(null)
    const timeoutRef = ref<any>()
    const copiedToClipboardRef = ref<boolean>(false)
    const submitting = ref<boolean>(false)
    const humanReadableLocation = ref<HumanReadableLocationForPromotion | null>(null)

    const events = getEventsForPromotion(promotionName)

    const supportsNativeShare = javascriptNativeInterop.isAsyncSupported(NativeAsyncFunction.share)

    const shouldDisplayNativeShareButton = computed(() => {
        return !!successRef.value && supportsNativeShare
    })

    const shouldDisplayCopyLinkButton = computed(() => {
        return !!successRef.value && !supportsNativeShare
    })

    watch(shouldDisplayNativeShareButton, (newValue, oldValue) => {
        if (newValue === oldValue) {
            return
        }
        if (newValue) {
            logEvent(events.viewNativeShare, {})
        }
    })

    watch(shouldDisplayCopyLinkButton, (newValue, oldValue) => {
        if (newValue === oldValue) {
            return
        }
        if (newValue) {
            logEvent(events.viewCopyLink, {})
        }
    })

    const onClaim = async () => {
        submitting.value = true
        try {
            logEvent(events.claimClick, {})
            const result = await claimQuickPromotionReward(promotionName)
            successRef.value = result.data?.success ?? false
        } catch (error) {
            logger.fatal(`Error claiming ${promotionName}`, error)
        } finally {
            // If successRef is still null, set it to false
            successRef.value = successRef.value ?? false
            submitting.value = false
        }
    }

    /** NOTE that this is a higher order function. Takes in a string and returns a function that can call native share */
    const shareWithFriends = (message: string) => async () => {
        try {
            logEvent(events.shareClick, {})
            const result = await javascriptNativeInterop.share(message)
            logEvent(events.shareEvent, result)
            logger.info(`Native share result for ${promotionName}: ${JSON.stringify(result)}`)
        } catch (error) {
            logger.fatal(`Error sharing ${promotionName}`, error)
        }
    }

    /** NOTE that this is a higher order function. Takes in a string and returns a function that can copy text to clipboard */
    const copyLink = (message: string) => async () => {
        try {
            logEvent(events.copyClick, {})
            await navigator.clipboard.writeText(message)
            logEvent(events.copyEvent, {})
        } finally {
            copiedToClipboardRef.value = true
            clearTimeout(timeoutRef.value)
            timeoutRef.value = setTimeout(() => {
                copiedToClipboardRef.value = false
            }, 3000)
        }
    }

    const trySetHumanReadableLocationForCustomer = async (): Promise<void> => {
        try {
            const result = await getQuickPromotionHumanReadableLocationForCustomer(promotionName)
            humanReadableLocation.value = result.data?.payload?.humanReadableLocation
        } catch (error) {
            logger.error(`Error getting human readable location for ${promotionName}`, error)
        }
    }

    return {
        successRef,
        copiedToClipboardRef,
        submitting,
        humanReadableLocation,
        shouldDisplayNativeShareButton,
        shouldDisplayCopyLinkButton,
        onClaim,
        supportsNativeShare,
        shareWithFriends,
        copyLink,
        trySetHumanReadableLocationForCustomer,
    }
}

export const useQuickPromotionFeedUnitCommons = (promotionName: PromotionName, promotionPagePath: RoutePaths) => {
    const events = getEventsForPromotion(promotionName)
    const router = useRouter()
    const shouldShowEnrolledFeedUnit = ref(false)
    const shouldShowClaimedFeedUnit = ref(false)
    const submitting = ref<boolean>(false)

    const trySetShouldShowFeedUnitRefs = async (): Promise<void> => {
        try {
            const result = await getQuickPromotionStatus(promotionName)
            if (!result.data?.success) {
                logger.error(`Error getting quick promotion status for ${promotionName}`, result.data?.error)
                return
            }
            shouldShowEnrolledFeedUnit.value = result.data?.payload?.status === PromotionStatus.isEnrolled
            shouldShowClaimedFeedUnit.value = [PromotionStatus.activatedByCustomer, PromotionStatus.promotionPaid].includes(result.data?.payload?.status)
        } catch (error) {
            logger.fatal(`Error getting quick promotion status for ${promotionName}`, error)
        }
    }

    const onClaimFeedUnit = async () => {
        submitting.value = true
        try {
            logEvent(events.claimFeedUnitClick, {})
            await router.push(promotionPagePath)
        } finally {
            submitting.value = false
        }
    }

    return {
        shouldShowEnrolledFeedUnit,
        shouldShowClaimedFeedUnit,
        submitting,
        trySetShouldShowFeedUnitRefs,
        onClaimFeedUnit,
    }
}

export const useRestaurants7PromotionCommons = () => {
    // These ugly functions are due to the fact that dynamically generating translation pointers can make debugging very difficult, so this lets you easily trace the translation
    const getRestaurants7SuccessDescriptionForLocation = (humanReadableLocation: HumanReadableLocationForPromotion | null): string => {
        if (humanReadableLocation === HumanReadableLocationForPromotion.ohio) {
            return i18n.t('pages.RestaurantsPromotion.successDescription.ohio')
        } else if (humanReadableLocation === HumanReadableLocationForPromotion.phoenix) {
            return i18n.t('pages.RestaurantsPromotion.successDescription.phoenix')
        } else {
            return i18n.t('pages.RestaurantsPromotion.successDescription.generic')
        }
    }

    const getRestaurants7TextBelowImagesForLocation = (humanReadableLocation: HumanReadableLocationForPromotion | null): string => {
        if (humanReadableLocation === HumanReadableLocationForPromotion.ohio) {
            return i18n.t('pages.RestaurantsPromotion.textBelowImages.ohio')
        } else if (humanReadableLocation === HumanReadableLocationForPromotion.phoenix) {
            return i18n.t('pages.RestaurantsPromotion.textBelowImages.phoenix')
        } else {
            return i18n.t('pages.RestaurantsPromotion.textBelowImages.generic')
        }
    }

    const getRestaurants7ShareMessageForLocation = (humanReadableLocation: HumanReadableLocationForPromotion | null): string => {
        if (humanReadableLocation === HumanReadableLocationForPromotion.ohio) {
            return i18n.t('pages.RestaurantsPromotion.shareMessage.ohio')
        } else if (humanReadableLocation === HumanReadableLocationForPromotion.phoenix) {
            return i18n.t('pages.RestaurantsPromotion.shareMessage.phoenix')
        } else {
            return i18n.t('pages.RestaurantsPromotion.shareMessage.generic')
        }
    }

    return {
        getRestaurants7SuccessDescriptionForLocation,
        getRestaurants7TextBelowImagesForLocation,
        getRestaurants7ShareMessageForLocation,
    }
}
