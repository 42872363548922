import { logger } from './logger'
import trackerVuex from '@openreplay/tracker-vuex'
import { _ActionsTree, _GettersTree, StateTree, Store } from 'pinia'
import { OpenReplay } from 'aven_shared/src/services/openReplay'
import { httpClient } from '@/services/http-client'

class AvenMyOpenReplay {
    private piniaTrackerPluginFunction: Function | undefined
    private readonly trackedPiniaStores: Set<string> = new Set()
    private customOpenReplay: OpenReplay

    constructor() {
        // Create a custom instance with our project key
        this.customOpenReplay = new OpenReplay(process.env.VUE_APP_OPENREPLAY_PROJECT_KEY)
    }

    public init = async (sessionId: string, userTraits: any) => {
        logger.info(`Initializing AvenMy OpenReplay with sessionId: ${sessionId}`)

        try {
            // Use our custom instance to initialize OpenRePlay
            const sessionRecordingUrl = await this.customOpenReplay.init(sessionId, userTraits, [], httpClient)

            // Set up Pinia tracking now that we have the tracker
            if (this.customOpenReplay.openReplayTracker && !this.piniaTrackerPluginFunction) {
                this.piniaTrackerPluginFunction = this.customOpenReplay.openReplayTracker.use(trackerVuex())
            }

            return sessionRecordingUrl
        } catch (error) {
            logger.error(`OpenReplayTracker failed to initialize for sessionId: ${sessionId}.`, error)
            return undefined
        }
    }

    public trySetMetadata = (metadata: Record<string, string>) => {
        // Use our custom instance to set metadata
        return this.customOpenReplay.trySetMetadata(metadata)
    }

    // NOTE: We can't use logger because calls to logger access certain stores, thus causing infinite loops
    public setUpPiniaStoreTracking = <Id extends string = string, S extends StateTree = StateTree, G = _GettersTree<S>, A = _ActionsTree>(store: Store<Id, S, G, A>) => {
        if (this.trackedPiniaStores.has(store.$id)) {
            // Specifically not logging since this log would be pretty spammy and we get logs on error or success
            return
        }
        // Localhost OpenReplay is not enabled, so we won't even try to register for Pinia tracking
        if (process.env.VUE_APP_NODE_ENV === 'development') {
            console.log(`Not tracking Pinia store ${store.$id} in development`)
            this.trackedPiniaStores.add(store.$id)
            return
        }
        if (!this.customOpenReplay.openReplayTracker) {
            // If OpenReplay.openReplayTracker is nullish, we can't track the Pinia store
            // Note we don't log to logger or console because there are some races between
            // the instantiation/initialization of OpenReplay and use of stores, so it's
            // likely a log would spam the console.
            return
        }
        if (!this.customOpenReplay.openReplayTracker.isActive()) {
            // OpenReplay.openReplayTracker must be active before trying to track the Pinia store
            // Note we don't log to logger or console because there are some races between
            // the instantiation/initialization of OpenReplay and use of stores, so it's
            // likely a log would spam the console.
            return
        }
        if (!this.piniaTrackerPluginFunction) {
            // OpenReplay.piniaTrackerPluginFunction must be instantiated before trying to track the Pinia store
            // Note we don't log to logger or console because there are some races between
            // the instantiation/initialization of OpenReplay and use of stores, so it's
            // likely a log would spam the console.
            return
        }
        console.log(`Setting up OpenReplay to track Pinia store ${store.$id}`)
        const piniaPlugin = this.piniaTrackerPluginFunction(store.$id)
        piniaPlugin(store)
        this.trackedPiniaStores.add(store.$id)
    }
}

export const openReplay = new AvenMyOpenReplay()
