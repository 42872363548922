<template>
    <header>
        <div
            class="container"
            data-testid="app-header-title"
        >
            <h3
                class="heading-3 mb-0 d-flex flex-row align-items-center"
                @click="onTitleBackClickHandler"
                @keydown="onTitleBackClickHandler"
                data-testid="app-header-title-text"
            >
                <img
                    v-if="!!onTitleBackClick"
                    class="left-arrow"
                    src="@/assets/left-arrow.svg"
                    alt="Title Back Icon"
                >
                {{ title }}
            </h3>
            <div class="d-flex align-items-center">
                <div
                    v-if="shouldDisplayPayItForwardButton"
                    @click="() => goToPayItForward(GoToPayItForwardCaller.PifPill)"
                    @keydown.enter="() => goToPayItForward(GoToPayItForwardCaller.PifPill)"
                    class="pay-it-forward-btn text-btn-shared"
                >
                    {{ payItForwardCta }}
                </div>
                <div
                    @click="showSupportPage"
                    @keydown.enter="showSupportPage"
                    class="help-text-btn text-btn-shared"
                    data-testid="button-help-text-header"
                >
                    {{ $t('pages.appHeader.help') }}
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import zendeskMixin from '@/mixins/zendeskMixin'
    import goToPayItForward, { GoToPayItForwardCaller } from '@/mixins/goToPayItForward'
    import format from '@/mixins/format'
    import payItForwardMixin from '@/mixins/payItForwardMixin'
    import { PifScreen } from '@/utils/pifManager'
    import { RouteNames } from '@/routes/router.types'
    import { EXPERIMENT_SPEC_GROUP_NAME, EXPERIMENT_TYPE, isExperimentEnabled } from '@/experiments/getOverrideForExperiment'
    import { PifRewardType } from 'aven_types'

    const pathToLabel = [
        {
            name: RouteNames.STATEMENTS,
            label: 'statements',
        },
        {
            name: RouteNames.REWARDS,
            label: 'rewards',
        },
        {
            name: RouteNames.CARD,
            label: 'activity',
        },
        {
            name: RouteNames.ACTIVITY,
            label: 'activity',
        },
    ]

    export default {
        name: 'AppHeader',
        mixins: [zendeskMixin, goToPayItForward, format, payItForwardMixin],
        props: {
            title: {
                type: String,
                required: true,
            },
            onTitleBackClick: {
                type: Function,
                required: false,
            },
        },
        async mounted() {
            await this.getPayItForwardData(PifScreen.HEADER)
            this.findScrollContainer()
            window.addEventListener('resize', this.findScrollContainer) // Re-evaluate on screen resize
        },
        beforeDestroy() {
            if (this.scrollContainer) {
                this.scrollContainer.removeEventListener('scroll', this.handleScroll)
            }
            window.removeEventListener('resize', this.findScrollContainer)
        },
        data() {
            return {
                showingSupport: true,
                isScrolled: false,
                scrollContainer: null,
            }
        },
        computed: {
            GoToPayItForwardCaller() {
                return GoToPayItForwardCaller
            },
            label: function () {
                const obj = pathToLabel.find((o) => o.name === this.$route.name)
                return obj ? obj.label : ''
            },
            payItForwardAmount: function () {
                return this.toFormattedUSDNoCents(this.payItForwardMaxAmount)
            },
            shouldDisplayPayItForwardButton: function () {
                return this.shouldShowPayItForward() && [PifRewardType.classicPif, PifRewardType.ucc100UsdReferral].includes(this.rewardType)
            },
            payItForwardCta: function () {
                if (!this.shouldDisplayPayItForwardButton) {
                    return ''
                }
                if (this.rewardType === PifRewardType.classicPif) {
                    return this.$t('pages.appHeader.pifCta.classicPif', { payItForwardAmount: this.payItForwardAmount })
                }
                if (this.rewardType === PifRewardType.ucc100UsdReferral) {
                    return this.$t('pages.appHeader.pifCta.ucc100UsdReferral', { payItForwardAmount: this.payItForwardAmount })
                }
                return ''
            },
        },
        methods: {
            findScrollContainer() {
                if (this.scrollContainer) {
                    this.scrollContainer.removeEventListener('scroll', this.handleScroll)
                }

                const isMediumScreenOrSmaller = window.innerWidth <= 768

                this.scrollContainer = isMediumScreenOrSmaller ? window : document.querySelector('main.nav-visible')

                if (this.scrollContainer) {
                    this.scrollContainer.addEventListener('scroll', this.handleScroll)
                } else {
                    console.warn('No valid scroll container found.')
                }
            },
            handleScroll() {
                if (!this.scrollContainer) return

                const scrollPosition = this.scrollContainer === window ? window.scrollY : this.scrollContainer.scrollTop

                this.isScrolled = scrollPosition > 10

                const header = document.querySelector('header')
                if (header) {
                    if (this.isScrolled && this.scrollContainer === document.querySelector('main.nav-visible')) {
                        header.classList.add('scrolled-desktop')
                    } else if (this.isScrolled) {
                        header.classList.add('scrolled')
                    } else {
                        header.classList.remove('scrolled')
                        header.classList.remove('scrolled-desktop')
                    }
                }
            },
            showSupportPage: async function () {
                if (isExperimentEnabled(EXPERIMENT_TYPE.multiPage, EXPERIMENT_SPEC_GROUP_NAME.redirectHelpAndSupportToZendeskChat)) {
                    this.$logEvent('click_button_aven_my_help_to_zendesk_chat')
                    this.showWebWidget()
                } else {
                    this.$logEvent('click_button_aven_my_help')
                    return await this.$router.push({ path: '/support', query: { label: this.label } })
                }
            },
            onTitleBackClickHandler: function () {
                if (this.onTitleBackClick) {
                    this.onTitleBackClick()
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/components/appHeader';
</style>
