<script setup lang="ts">
    import { computed, onMounted, ref } from 'vue'
    import { PromotionName } from 'aven_types'
    import { logEvent } from '@/services/http-client'
    import BaseButton from '@/components/base/BaseButton.vue'
    import CardBlock from '@/components/CardBlock.vue'
    import SectionHeader from '@/components/SectionHeader.vue'
    import { toFormattedAprStripTrailingZerosAfterDecimalPoint } from '@/mixins/format'
    import { activateQuickPromotionOffer } from '@/services/api'
    import { useUpgradeOffersStore } from '@/store/upgradeOffersStore'
    import { useOverviewStore } from '@/store/overviewStore'
    import ModalDialog from 'aven_shared/src/components/ModalDialog.vue'
    import { isExperimentEnabled } from '@/experiments/getOverrideForExperiment'
    import { ExperimentSpecGroupName, ExperimentType } from 'aven_shared/src/utils/abExperimentsUtils'

    const promotionName = PromotionName.costcoFreeMembership
    // 4% = 2% from Costco Executive Membership and 2% from Aven
    const COSTCO_CASHBACK_PERCENTAGE = 0.04
    const overviewStore = useOverviewStore()
    const upgradeOffersStore = useUpgradeOffersStore()
    const originatingSourceName = 'costcoUpgradeFeedUnit'
    const submitting = ref(false)
    const isCostcoExperimentEnabled = isExperimentEnabled(ExperimentType.uccUpgradePromotion, ExperimentSpecGroupName.upgradeWithCostco)

    const showCostcoDisclosuresModal = ref(false)
    const showLowestRateGuaranteedModal = ref(false)

    const displayCard = computed((): boolean => {
        return !!upgradeOffersStore.upgradeOfferToDisplay && isCostcoExperimentEnabled
    })

    const onUpgradeClick = async () => {
        logEvent('click_costco_upgrade_ucc_to_home_button', {
            offer: upgradeOffersStore.upgradeOfferToDisplay,
        })
        await activateQuickPromotionOffer(promotionName)
        // Todo Might need to update the Aven My app to open this in mobile browser
        window.open(upgradeOffersStore.getUpgradeOfferReturnLink(originatingSourceName), '_blank')
    }

    const handleToggleCostcoDisclosures = () => {
        showCostcoDisclosuresModal.value = !showCostcoDisclosuresModal.value
        logEvent('click_costco_upgrade_toggle_costco_disclosures', {
            costcoDisclosuresModalNewValue: showCostcoDisclosuresModal.value,
        })
    }

    const handleToggleLowestRateDisclosures = () => {
        showLowestRateGuaranteedModal.value = !showLowestRateGuaranteedModal.value
        logEvent('click_costco_upgrade_toggle_lowest_rate_disclosures', {
            lowestRateGuaranteedModalNewValue: showLowestRateGuaranteedModal.value,
        })
    }

    onMounted(async () => {
        if (displayCard.value) {
            logEvent('view_costco_upgrade_ucc_to_home_feed_unit', {})
        }
    })
</script>

<template>
    <div>
        <div
            v-if="displayCard"
            class="mb-2"
        >
            <section-header override-class="container mt-3 mb-1 text-left">
                {{ $t('components.costcoUpgradeFeedUnit.yourOffers') }}
            </section-header>
            <card-block
                class="card-block"
                :no-horizontal-padding="true"
                :no-vertical-padding="true"
            >
                <h6 class="tw-mt-4 tw-mb-2 tw-mx-6 tw-font-bold">
                    {{ $t('components.costcoUpgradeFeedUnit.title') }}
                </h6>
                <!-- This monstrosity of breaking the text into parts is all so the red underline should 
                 show up in the right spot on everyone's device -->
                <div class="tw-text-sm tw-mx-6">
                    <div>
                        {{ $t('components.costcoUpgradeFeedUnit.description.pt1') }}
                    </div>
                    <div class="tw-relative">
                        <div
                            @click="handleToggleCostcoDisclosures"
                            @keydown.enter="handleToggleCostcoDisclosures"
                            v-html="$t('components.costcoUpgradeFeedUnit.description.pt2', { cashBackPercentage: toFormattedAprStripTrailingZerosAfterDecimalPoint(COSTCO_CASHBACK_PERCENTAGE) })"
                        />
                        <img
                            class="tw-absolute tw-left-0 tw-top-4"
                            src="@/assets/images/global/red-underline.svg"
                            alt="red underline"
                        >
                    </div>
                </div>
                <img
                    class="tw-mt-6"
                    alt="Costco Card Free Executive Membership"
                    src="@/assets/images/global/costco-upgrade.webp"
                >
                <base-button
                    @click="onUpgradeClick"
                    class="tw-mx-6 tw-my-4"
                    :submitting="submitting"
                    data-testid="costco-upgrade-claim-button"
                >
                    {{ $t('components.costcoUpgradeFeedUnit.upgradeButtonTitle') }}
                </base-button>
                <div class="gray-div tw-mx-6 tw-mb-6">
                    <li v-html="$t('components.costcoUpgradeFeedUnit.limitsUpTo250k')" />
                    <li v-html="$t('components.costcoUpgradeFeedUnit.allOnline')" />
                    <li
                        @click="handleToggleLowestRateDisclosures"
                        @keydown.enter="handleToggleLowestRateDisclosures"
                        v-html="$t('components.costcoUpgradeFeedUnit.lowestRatesGuaranteed')"
                    />
                </div>
            </card-block>
            <!-- Costco Footnote Modal -->
            <modal-dialog
                :show="showCostcoDisclosuresModal"
                @close="handleToggleCostcoDisclosures"
            >
                <div class="tw-px-4">
                    <ol class="tw-text-gray-400 tw-p-0 tw-text-[12px]">
                        <li v-html="overviewStore.costcoDisclosuresText" />
                    </ol>
                </div>
            </modal-dialog>
            <!-- Lowest Rates Guaranteed Footnote Modal -->
            <modal-dialog
                :show="showLowestRateGuaranteedModal"
                @close="handleToggleLowestRateDisclosures"
            >
                <div class="tw-px-4">
                    <ol
                        class="tw-text-gray-400 tw-p-0 tw-text-[12px]"
                        :start="2"
                    >
                        <li v-html="overviewStore.guaranteedLowestRateDisclosuresText" />
                    </ol>
                </div>
            </modal-dialog>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .gray-div {
        background-color: $gray-100;
        border-radius: 8px;
        padding: 12px;
        font-size: 14px;
    }
</style>
