import { useCustomerInfoStore } from '@/store/customerInfoStore'

export const initZendeskScript = async () => {
    const script = document.createElement('script')
    script.defer = true
    script.id = 'ze-snippet'
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=380ebd06-0223-483e-8588-5c13a449ce55'
    script.crossOrigin = 'anonymous'
    script.onload = function () {
        // Allow Chat by Default
        window.zE('webWidget', 'updateSettings', {
            webWidget: {
                chat: {
                    suppress: false,
                    departments: {
                        enabled: ['CH Chat Team'],
                        select: 'CH Chat Team',
                    },
                },
            },
        })

        // Hide Zendesk Launcher by Default & on Widget Close
        window.zE('webWidget', 'hide')
        window.zE('webWidget:on', 'close', function () {
            window.zE('webWidget', 'hide')
        })
    }

    document.body.appendChild(script)
}

export default {
    methods: {
        showWebWidget: function () {
            const customerInfoStore = useCustomerInfoStore()
            this.$logEvent('view_support_form_button')
            if (!window.zE) {
                return
            }
            window.zE('webWidget', 'show')
            window.zE('webWidget', 'open')
            window.zE('webWidget', 'prefill', {
                name: {
                    value: customerInfoStore.fullName,
                },
                email: {
                    value: customerInfoStore.email,
                },
            })
        },
    },
}
