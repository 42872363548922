<template>
    <div
        v-if="displayCard"
        class="tw-mb-4"
    >
        <div class="tw-text-gray-400 tw-text-[12px] tw-py-2 tw-px-6 tw-tracking-[2px]">
            {{ $t('components.uccToHomeDryrunUpgradeCard.sectionTitle') }}
        </div>
        <aven-financial-card-offer
            :on-displayed-callback="onDisplayedCallback"
            :handle-button-click="handleButtonClick"
            :handle-display-disclosures="handleToggleDisclosures"
            :aven-upgrade-data="upgradeData"
            :pill-text="i18n.t('components.uccToHomeDryrunUpgradeCard.title')"
            :extra-pill-classes="`tw-bg-white !tw-text-black !tw-tracking-normal !tw-p-0 !tw-h-5 !tw-mb-0 !tw-text-[16px]`"
        />
        <modal-dialog
            :show="displayDisclosures"
            @close="handleToggleDisclosures"
        >
            <div class="tw-px-4">
                <ol class="tw-text-gray-400 tw-p-0 tw-text-[12px]">
                    <li v-html="homeDisclosures" />
                </ol>
            </div>
        </modal-dialog>
    </div>
</template>

<script setup lang="ts">
    import { UpgradeCardType } from 'aven_types'
    import { logEvent } from '@/services/http-client'
    import AvenFinancialCardOffer from 'aven_shared/src/components/AvenFinancialCardOffer.vue'
    import { i18n } from '@/utils/i18n'
    import { useRouter } from 'vue-router/composables'
    import { useUpgradeOffersStore } from '@/store/upgradeOffersStore'
    import { computed, ref } from 'vue'
    import { RouteNames } from '@/routes/router.types'
    import ModalDialog from 'aven_shared/src/components/ModalDialog.vue'
    import { AvenHomeCardUpgradeVariant } from '@/views/avenHomeCardUpgrade.types'

    const router = useRouter()
    const upgradeOffersStore = useUpgradeOffersStore()

    const displayDisclosures = ref(false)

    const homeDisclosures = computed(() => {
        return upgradeOffersStore.getUpgradeDisclosures(AvenHomeCardUpgradeVariant.lineSize)
    })

    const upgradeData = computed(() => {
        return {
            upgradeCardType: UpgradeCardType.dryrunMoap,
            newUwPolicyName: 'default',
            maxLineSize: upgradeOffersStore.upgradeOfferToDisplay.lineSize,
            minApr: upgradeOffersStore.upgradeOfferToDisplay.apr,
            cashbackPercent: upgradeOffersStore.upgradeOfferToDisplayCashBackRatio,
            cashbackAnnualLimit: upgradeOffersStore.cashBackAnnualLimit,
        }
    })

    const displayCard = computed(() => {
        return !!upgradeOffersStore.upgradeOfferToDisplay
    })

    const handleButtonClick = async () => {
        logEvent('click_aven_my_ucc_to_home_dryrun_moap_upgrade_card', {
            offer: upgradeOffersStore.upgradeOfferToDisplay,
        })
        await router.replace({
            name: RouteNames.AVEN_HOME_CARD_UPGRADE,
            params: {
                variant: AvenHomeCardUpgradeVariant.lineSize,
                originatingSourceName: 'moapDryrunUpgradeCard',
            },
        })
    }

    const handleToggleDisclosures = () => {
        displayDisclosures.value = !displayDisclosures.value
        logEvent('click_aven_my_ucc_to_home_dryrun_moap_upgrade_card_toggle_disclosures', {
            displayDisclosures: displayDisclosures.value,
        })
    }

    const onDisplayedCallback = () => {
        logEvent('view_aven_my_ucc_to_home_dryrun_moap_upgrade_card', {
            offer: upgradeOffersStore.upgradeOfferToDisplay,
        })
    }
</script>

<style lang="scss" scoped></style>
