import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { logger } from '@/utils/logger'
import { authorizePasswordAndGetProductInfo, DebtProductAndMetaDataSet, passwordLogin, ProductInfoResponse } from '@/services/avenAppApi'
import { logEvent, runWithRetryLogic } from '@/services/http-client'
import { NATIVE_MESSAGES, postLoginNavigation, sendMessageToNativeApp } from '@/utils/sharedLogic'
import { useSessionStore } from '@/store/sessionStore'
import { useOverviewStore } from '@/store/overviewStore'
import { useDeviceInfoStore } from '@/store/deviceInfoStore'
import { pifManager } from '@/utils/pifManager'
import { useAuthStore } from '@/store/authStore'
import { useCustomerInfoStore } from '@/store/customerInfoStore'
import { useUpgradeOffersStore } from '@/store/upgradeOffersStore'
import { useEngagementSipOffersStore } from '@/store/engagementSipOffersStore'

export enum BiometricsError {
    cancelledByUser = 'cancelledByUser',
    noPermission = 'noPermission',
    noCredentialsFound = 'noCredentialsFound',
    platFormError = 'platFormError',
    invalidSavedCredentials = 'invalidSavedCredentials',
}

// Keep in sync with AuthInfo in the native projects:
// Android - aven_android/app/src/main/java/com/aven/util/PreferenceHelper.kt
// iOS - aven_ios/avenApp/avenApp/auth/AuthService.swift
export interface AuthInfo {
    accountPhoneNumber: string
    sessionId: string
    sessionAccessJWT: string
    // Todo We keep jwt here for backwards compatibility with old app versions. We
    //  can remove once nobody is using iOS v1.0.39 or lower and Android v1.1.53 or lower, which
    //  will require us to use an app block event once the new apps are live. At that point, all
    //  apps will be using accessJWT, refreshJWT, and accessExpiry instead.
    jwt: JWTToken
    accessJWT: string
    refreshJWT: string
    accessExpiry: number
    coreCardCustomerId?: string
    creditCardCustomerId?: string
    applicantId?: string
    passcode?: string
}

export interface NativeBiometricsResult {
    coreCardCustomerId?: string
    creditCardCustomerId?: string
    accountPhoneNumber: string
    passcode: string
    errorCode: string
}

export interface JWTToken {
    accessJWT: string
    refreshJWT: string
    accessExpiry: number
}

export interface BlockAppEvent {
    url?: string
    title: string
    description: string
    buttonText: string
    buttonActionUrl: string
}

export class AppBlockedError extends Error {
    public readonly blockEvent: BlockAppEvent

    constructor(blockAppEvent: BlockAppEvent) {
        super('App Blocked')
        // Set the prototype explicitly.
        this.blockEvent = blockAppEvent
        Object.setPrototypeOf(this, AppBlockedError.prototype)
    }
}

/**
 * Gets all info necessary to render the app on open. This includes:
 * 1. Account overview
 * 2. PIF data
 * Then navigates to the correct post-login page.
 */
export const getAccountInfoAndNavigate = async () => {
    logEvent('event_start_get_account_info_and_navigate', {})
    await runWithRetryLogic(
        () =>
            useOverviewStore().updateAccountOverview({
                debtProductAndMetaDataSet: DebtProductAndMetaDataSet.all,
            }),
        3,
        1000
    )

    // wait at most 5 seconds for the upgrade to finish. Otherwise, the promise will
    // continue to run and we'll grab the results when ready. We ideally have the upgrade
    // ready when the cardholder sees Activity, otherwise the unit advertising the
    // offer will just pop into view.
    const upgradeOfferPromise = await Promise.race([new Promise((resolve) => setTimeout(resolve, 5000)), useUpgradeOffersStore().tryRunAndGetUccCardholderHomeUpgradeOffer()])

    // Pull the engagement full draw offer post-login, because we may want to present the offer on a post-login prompt or activity page.
    const engagementFullDrawOfferPromise = useEngagementSipOffersStore().tryGetEngagementFullDrawInstallmentOffer()

    // Since we render PIF links on nearly every page, we'll preload PIF data after as part of the post-login
    // flow. We *MUST* load after account overview - not before, not in parallel - because loading PIF data
    // depends on information returned in account overview.
    const preloadPifPromise = pifManager.tryPreloadPifData()

    await Promise.allSettled([upgradeOfferPromise, engagementFullDrawOfferPromise, preloadPifPromise])

    if (useOverviewStore().isInternalEmployee && window.zE) {
        window.zE('webWidget', 'updateSettings', {
            webWidget: {
                chat: {
                    suppress: false,
                },
            },
        })
    }

    // Otherwise, continue to normal login flow
    logger.log(`postLoginNavigation: using default logic`)
    return await postLoginNavigation()
}

export default {
    methods: {
        inflateFromNativeBiometricsResult(biometricsResult: NativeBiometricsResult) {
            appSessionStorage.setItem(localStorageKey.creditCardCustomerId, biometricsResult.creditCardCustomerId)
            appSessionStorage.setItem(localStorageKey.accountPhoneNumber, biometricsResult.accountPhoneNumber)
        },
        // Todo Move method to authStore.ts
        async authorizePasswordAndGetProductInfo(password: string) {
            this.$logEvent('event_start_authorize_password_get_product_info')
            const response = await authorizePasswordAndGetProductInfo(password)
            const productInfoResponse: ProductInfoResponse = response.data.payload
            logger.info(`Product Info Response: ${JSON.stringify(productInfoResponse)}`)
            const customerInfoStore = useCustomerInfoStore()
            customerInfoStore.$patch({
                customerTimeZone: productInfoResponse.customerTimeZone,
                productInfoDetails: productInfoResponse.productInfoDetails,
            })
            if (productInfoResponse.productInfoDetails.length === 1) {
                logger.info(`Setting product type to ${productInfoResponse.productInfoDetails[0].productType}`)
                customerInfoStore.currentlySelectedProductInfo = productInfoResponse.productInfoDetails[0]
            } else {
                logger.info(`Letting user select product type`)
            }
            return productInfoResponse
        },
        async injectAuthInfoToNative(passcode: string, jwt: JWTToken, passcodeReset: boolean = false) {
            // TODO: Split passcode and JWT into separate endpoints
            const userInfo = {
                creditCardCustomerId: appSessionStorage.getItem(localStorageKey.creditCardCustomerId),
                passcode: encodeURIComponent(passcode),
            }
            const info: AuthInfo = this.createAuthInfo(jwt, userInfo)
            const authInfoQueryParams = this.createAuthInfoQueryParams(info, passcodeReset)

            await sendMessageToNativeApp(NATIVE_MESSAGES.SIGNED_IN, `${authInfoQueryParams.join('&')}`)
        },
        async injectOriginationAuthInfoToNative(jwt: JWTToken) {
            const extraInfo = {
                applicantId: appSessionStorage.getItem(localStorageKey.applicantId),
            }
            const info: AuthInfo = this.createAuthInfo(jwt, extraInfo)
            const authInfoQueryParams = this.createAuthInfoQueryParams(info)

            await sendMessageToNativeApp(NATIVE_MESSAGES.ORIGINATION_STARTED, `${authInfoQueryParams.join('&')}`)
        },
        createAuthInfo(jwt: JWTToken, userInfo: object): AuthInfo {
            const sessionStore = useSessionStore()
            return {
                accountPhoneNumber: appSessionStorage.getItem(localStorageKey.accountPhoneNumber),
                sessionId: sessionStore.sessionId,
                sessionAccessJWT: sessionStore.sessionAccessJWT,
                jwt,
                refreshJWT: jwt.refreshJWT,
                accessJWT: jwt.accessJWT,
                accessExpiry: jwt.accessExpiry,
                ...userInfo, // Merging the extra info into the AuthInfo object
            }
        },
        createAuthInfoQueryParams(info: AuthInfo, passcodeReset?: boolean): string[] {
            // The strategy here is to send each value as a query param rather than a json object
            // because serialization & deserialization of an encoded json object has proven tricky
            // and bug-prone, particularly with respect to passwords. We also have to keep backwards
            // compatibility by including the 'session' param, which the current apps try to handle.
            const authInfoQueryParams = []
            for (const authInfoKey of Object.keys(info)) {
                authInfoQueryParams.push(`${authInfoKey}=${info[authInfoKey]}`)
            }
            // Todo We can remove 'session' once nobody is using iOS v1.0.39 or lower and Android v1.1.53 or
            //  lower. At that point, all apps will be able to parse each individual query param.
            authInfoQueryParams.push(`session=${JSON.stringify(info)}`)
            if (passcodeReset !== undefined) {
                authInfoQueryParams.push(`passcodeReset=${passcodeReset}`)
            }
            return authInfoQueryParams
        },
        // Todo Move method to authStore.ts
        async loginWithPassword(password: string, productType) {
            this.$logEvent('event_start_password_login')
            logger.log(`Logging in with password`)
            const response = await passwordLogin(password, productType)
            const data = response.data

            logger.info(`Logging in with password response: ${JSON.stringify(data)}`)

            useAuthStore().jwtToken = response.data.payload.jwt.accessJWT
            logger.info('Saved jwt in session storage')

            const isWebView = useDeviceInfoStore().isWebView
            const isSingleWebView = useDeviceInfoStore().isSingleWebView
            const shouldInjectSessionToNative = isSingleWebView && isWebView
            logger.info(`singleWebView: ${isSingleWebView}, isWebView: ${isWebView}`)
            if (shouldInjectSessionToNative) {
                await this.injectAuthInfoToNative(password, response.data.payload.jwt)
                if (appSessionStorage.getItem(localStorageKey.nativeInjectionLog)) {
                    // deeplink parsing happens before session starts. log is written to session storage
                    // now we send to backend post login
                    const log = JSON.parse(appSessionStorage.getItem(localStorageKey.nativeInjectionLog))
                    logger.info(`${localStorageKey.nativeInjectionLog}: ${log.map((l, i) => `${i}: ${l}`).join('\n')}`)
                    appSessionStorage.removeItem(localStorageKey.nativeInjectionLog)
                }
            } else {
                logger.log(`Didn't send reverse injection data, shouldInjectSessionToNative: ${shouldInjectSessionToNative}`)
            }

            if (appSessionStorage.getItem(localStorageKey.routerLog)) {
                // deeplink parsing happens before session starts. log is written to session storage
                // now we send to backend post login
                const log = JSON.parse(appSessionStorage.getItem(localStorageKey.routerLog))
                logger.info(`${localStorageKey.routerLog}: ${log.map((l, i) => `${i}: ${l}`).join('\n')}`)
                appSessionStorage.removeItem(localStorageKey.routerLog)
            }
        },
        presentAppBlockedView: function (payload) {
            logger.log(`appBlocked config: ${JSON.stringify(payload)}`)
            const blockEvent = payload.blockEvent || payload

            // see POST '/aven_app/appBlockEvents' endpoint
            // block event can be either a url
            // or a configuration dictionary
            if (payload.url) {
                window.location.href = blockEvent.url
            } else {
                this.$router.push({
                    name: 'AppBlocked',
                    params: {
                        title: blockEvent.title,
                        description: blockEvent.description,
                        buttonText: blockEvent.buttonText,
                        buttonActionUrl: blockEvent.buttonActionUrl,
                    },
                })
            }
        },
        getAccountInfoAndNavigate,
    },
}
