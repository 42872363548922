import { defineStore } from 'pinia'
import { openReplay } from '@/utils/openReplay'
import { getEngagementInstallmentLoanTerm } from '@/services/api'
import { inspect, logger } from '@/utils/logger'
import { EngagementSipOffers } from '@/store/engagementSipOffersStore.types'
import { ISimpleInterestLoanTerm, AmortizationSource } from 'aven_types'

export const useEngagementSipOffersStore = () => {
    const engagementSipOffersStore = defineStore('engagementSipOffers', {
        state: (): EngagementSipOffers => {
            return {
                fullDrawInstallmentOffer: null,
            }
        },
        getters: {
            hasFullDrawOffer(state): boolean {
                return !!state.fullDrawInstallmentOffer
            },
            fullDrawFeeRatio(state): number | null {
                return state.fullDrawInstallmentOffer?.feeRatio
            },
            fullDrawSimpleInterestLoanId(state): number | null {
                return state.fullDrawInstallmentOffer?.simpleInterestLoanId
            },
            fullDrawAmountWithFee(state): number | null {
                return state.fullDrawInstallmentOffer?.amountWithFee
            },
            fullDrawAmountWithoutFee(state): number | null {
                return state.fullDrawInstallmentOffer?.amountWithoutFee
            },
            fullDrawInstallmentLoanOption(state): ISimpleInterestLoanTerm | null {
                return state.fullDrawInstallmentOffer?.installmentLoanOption
            },
            fullDrawExpirationDate(state): Date | null {
                return state.fullDrawInstallmentOffer?.expirationDate
            },
        },
        actions: {
            async tryGetEngagementFullDrawInstallmentOffer(): Promise<void> {
                try {
                    const engagementFullDrawResponse = await getEngagementInstallmentLoanTerm(AmortizationSource.ENGAGEMENT_FULL_DRAW)
                    const engagementFullDrawPayload = engagementFullDrawResponse.data.payload
                    if (!engagementFullDrawResponse.data.success) {
                        logger.warn(`getEngagementInstallmentLoanTerm failed: ${inspect(engagementFullDrawResponse.data)}`)
                        return
                    } else if (engagementFullDrawPayload) {
                        logger.info(`Got engagementFullDrawPayload: ${inspect(engagementFullDrawPayload)}`)
                        this.fullDrawInstallmentOffer = engagementFullDrawPayload
                    }
                } catch (e) {
                    logger.warn(`getEngagementInstallmentLoanTerm failed`, e)
                }
            },
        },
    })()
    openReplay.setUpPiniaStoreTracking(engagementSipOffersStore)
    return engagementSipOffersStore
}
